import { render, staticRenderFns } from "./Combustivel.vue?vue&type=template&id=ed855418&scoped=true&"
import script from "./Combustivel.vue?vue&type=script&lang=js&"
export * from "./Combustivel.vue?vue&type=script&lang=js&"
import style0 from "./Combustivel.vue?vue&type=style&index=0&id=ed855418&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed855418",
  null
  
)

export default component.exports