<template>
    <div class="menu-page">
        <h1>Checklists de Auditoria</h1>

        <div>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="button-new" @click.native="openNew">Novo checklist</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.name" label="Nome do checklist" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-card tile flat>
                                <v-card-subtitle><b>Incluir itens no checklist</b></v-card-subtitle>
                                <div>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field autocomplete="nope" dense v-model="itemLabel" label="Texto do item" outlined hide-details="auto"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                                                        Adicionar item como
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item style="cursor: pointer;" @click.native="object.tasks.push({ type: 'text', label: itemLabel, resp: '' }); itemLabel = ''">
                                                        <v-list-item-title>Texto</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item style="cursor: pointer;" @click.native="object.tasks.push({ type: 'radio', label: itemLabel, resp: '' }); itemLabel = ''">
                                                        <v-list-item-title>Opções fixas</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card outlined tile>
                                <v-card-subtitle><b>Tarefas</b></v-card-subtitle>
                                <div class="pa-2">
                                    <v-row>
                                        <v-col cols="12" sm="6" v-for="(item, index) in object.tasks" :key="index">
                                            <v-radio-group v-model="object.tasks[index].resp" v-if="item.type == 'radio'">
                                                <span>
                                                    {{ item.label }}
                                                    <v-badge color="red" class="ml-4" icon="mdi-close-thick" @click.native="selectedChecklistItem = index; dialog2 = true"></v-badge>
                                                </span>

                                                <v-radio label="Sim" value="Sim"></v-radio>
                                                <v-radio color="error" label="Não" value="Não"></v-radio>
                                            </v-radio-group>

                                            <div v-if="item.type == 'text'">
                                                <span>
                                                    <v-badge color="red" icon="mdi-close-thick" @click.native="selectedChecklistItem = index; dialog2 = true"></v-badge>
                                                </span>
                                                <v-text-field  autocomplete="nope" dense v-model="object.tasks[index].resp" :label="item.label" disabled outlined hide-details="auto"></v-text-field>
                                            </div>
                                            
                                        </v-col>

                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar checklist
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o checklist <b>{{ object.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar item de checklist
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar esse item do checklist?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog2 = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog2 = false; object.tasks.splice(selectedChecklistItem, 1)">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "ChecklistView",

    data() {
        return {
            objects: [],
            dialogCreateUpdateObject: false,
            selectedChecklistItem: 0,
            dialog: false,
            dialog2: false,
            search: '',
            itemLabel: '',
            noDataText: 'Sem dados',
            object: { tasks: [] },
            headers: [
                { text: "Nome", value: "name" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true,
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getChecklists();
            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
            }
            this.loading = false;
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateChecklist(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.object = { tasks: [] };
            this.dialogCreateUpdateObject = true;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  