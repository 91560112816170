import axios from "axios";
import { emitToastr } from "./Utils";

//const API_URL = "http://localhost:3005/admin";
const API_URL = "https://ges360.com.br/admin";

class Api {
  login = async (email, password, remember) => {
    const endpoint = "/auth";
    let result = await this.authPost(endpoint, { email, password, remember });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  getRemoteUser = async () => {
    const user = await this.authPost("/get-user", {}, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
  };

  recoveryPassword = async (email) => {
    const resp = await this.authPost("/send-password-recovery", { email: email }, {});
    return resp;
  };

  changePassword = async (email, password, recovery_token) => {
    const resp = await this.authPost("/change-password", { email, password, recovery_token }, {});
    return resp;
  };

  getProfiles = async () => {
    const resp = await this.authPost("/get-profiles", {}, {});
    return resp;
  };

  createOrUpdateProfile = async (profile) => {
    const resp = await this.authPost("/create-or-update-profile", { profile }, {});
    return resp;
  };

  getLocations = async () => {
    const resp = await this.authPost("/get-locations", {}, {});
    return resp;
  };

  createOrUpdateLocation = async (object) => {
    const resp = await this.authPost("/create-or-update-location", { object }, {});
    return resp;
  };

  getSections = async (region) => {
    const resp = await this.authPost("/get-sections", region, {});
    return resp;
  };

  createOrUpdateSection = async (object) => {
    const resp = await this.authPost("/create-or-update-section", { object }, {});
    return resp;
  };

  getUsers = async (region) => {
    const resp = await this.authPost("/get-users", region, {});
    return resp;
  };

  createOrUpdateUser = async (object) => {
    const resp = await this.authPost("/create-or-update-user", { user: object }, {});
    return resp;
  };

  getFuels = async () => {
    const resp = await this.authPost("/get-fuels", {}, {});
    return resp;
  };

  createOrUpdateFuel = async (object) => {
    const resp = await this.authPost("/create-or-update-fuel", { object }, {});
    return resp;
  };

  getVehicles = async (region) => {
    const resp = await this.authPost("/get-vehicles", region, {});
    return resp;
  };

  createOrUpdateVehicle = async (object) => {
    const resp = await this.authPost("/create-or-update-vehicle", { object }, {});
    return resp;
  };

  getUserVehicles = async () => {
    const resp = await this.authPost("/get-user-vehicle", {}, {});
    return resp;
  };

  getVehicleDailyRecords = async (start_time, end_time, vehicle_id) => {
    const resp = await this.authPost("/get-vehicle-daily-records", { start_time, end_time, vehicle_id }, {});
    return resp;
  };

  getDailyRecordsByFilter = async (filter, limit) => {
    const resp = await this.authPost("/get-daily-records-by-filter", { filter, limit }, {});
    return resp;
  };

  createOrUpdateDailyRecord = async (object, vehicle_id, section_id, location_id) => {
    const resp = await this.authPost("/create-or-update-daily-record", { ...object, vehicle: vehicle_id, section_id: section_id, location_id: location_id }, {});
    return resp;
  };

  getRenovationsByFilter = async (filter, limit) => {
    const resp = await this.authPost("/get-renovations-by-filter", { filter, limit }, {});
    return resp;
  };

  createOrUpdateRenovation = async (object) => {
    const resp = await this.authPost("/create-or-update-renovation", { object }, {});
    return resp;
  };

  uploadImage = async (image, rotate_wide_images = undefined) => {
    const resp = await this.authPost("/upload-image", { image, rotate_wide_images }, { multipart: true });
    return resp;
  };

  startGetReport = async () => {
    const resp = await this.authPost("/start-get-report", {}, {});
    return resp;
  };

  getAudits = async () => {
    const resp = await this.authPost("/get-audits", {}, {});
    return resp;
  };

  getAuditsByFilter = async (filter) => {
    const resp = await this.authPost("/get-audits-by-filter", { filter }, {});
    return resp;
  };

  createOrUpdateAudit = async (object) => {
    const resp = await this.authPost("/create-or-update-audit", { object }, {});
    return resp;
  };

  getAuditReports = async () => {
    const resp = await this.authPost("/get-audit-reports", {}, {});
    return resp;
  };

  createOrUpdateAuditReport = async (object) => {
    const resp = await this.authPost("/create-or-update-audit-report", { object }, {});
    return resp;
  };

  getChecklists = async () => {
    const resp = await this.authPost("/get-checklists", {}, {});
    return resp;
  };

  createOrUpdateChecklist = async (object) => {
    const resp = await this.authPost("/create-or-update-checklist", { object }, {});
    return resp;
  };

  testTelegram = async (object) => {
    const resp = await this.authPost("/test-telegram", { object }, {});
    return resp;
  };

  changeUserPassword = async (old_password, password) => {
    const resp = await this.authPost("/change-user-password", { old_password, password }, {});
    return resp;
  };

  checkErrorsWithStatusCode200 = (data) => {
    if (data.error) return data;
    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.inactivity) {
              emitToastr("warning", err.response.data.message, "Sessão expirada");
              setTimeout(() => {
                this.logout();
              }, 5000);
            } else {
              this.logout();
            }
          } else {
            emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          }
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.inactivity) {
              emitToastr("warning", err.response.data.message, "Sessão expirada");
              setTimeout(() => {
                this.logout();
              }, 5000);
            } else {
              this.logout();
            }
          } else {
            emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          }
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return err.response.data;
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
