import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/assets/global.css";
import VueMask from "v-mask";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueHtmlToPaper from "vue-html-to-paper";

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(CoolLightBox);
Vue.use(VueHtmlToPaper, { styles: ["https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css", "https://unpkg.com/kidlat-css/css/kidlat.css", "https://adminpe.dmcp.site/static/print.css", "https://ges360.com.br/static/print.css", "http://127.0.0.1:3005/static/print.css"], name: "GES360", windowTitle: "GES360", autoClose: false });

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
