<template>
    <div class="menu-page">
        <h1>Ges360</h1>
    </div>
</template>
  
<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "DashboardView",

    data() {
        return {
        };
    },

    components: {},

    methods: {
    },
};
</script>
  
<style scoped>

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  