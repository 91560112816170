<template>
    <div class="menu-page">
        <h1>Relatório de Reforma de Lojas</h1>

        <CoolLightBox :items="lightBoxItems || []" :index="lightBoxIndex" @close="lightBoxIndex = null"></CoolLightBox>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" height="130" type="card"></v-skeleton-loader>
                <v-card class="mb-4" v-if="!loading">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="locations" v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_sections" v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-text-field dense outlined hide-details="auto" v-model="filter.code" label="Código da loja"></v-text-field>
                            </v-col>


                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormatted" label="Data final" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="dialog2 = true">Relatório para impressão</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading || loadingFilter" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading && !loadingFilter" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.initialTime`]="{ item }">
                            {{ item.initialTime ? new Date(item.initialTime).toLocaleDateString() : '' }}
                        </template>

                        <template v-slot:[`item.finalTime`]="{ item }">
                            {{ item.finalTime ? new Date(item.finalTime).toLocaleDateString() : '' }}
                        </template>

                        <template v-slot:[`item.totalAmount`]="{ item }">
                            R${{ item.totalAmount }}
                        </template>

                        <template v-slot:[`item.calculatedAmount`]="{ item }">
                            R${{ item.calculatedAmount }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.native="object = item; dialogView = true" v-bind="attrs" v-on="on"> mdi-eye-outline</v-icon>
                                    </template>
                                    <span>Ver Detalhes</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogView" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>Detalhamento da reforma</span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogView = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <span class="text-subtitle-1" v-if="object.section_id"><b>Localidade e seção:</b> {{ object.section_id.location.name }} - {{ object.section_id.name }}</span>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <span class="text-subtitle-1"><b>Código da loja:</b> {{ object.code }}</span>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <span class="text-subtitle-1"><b>Valor apurado:</b> R${{ object.calculatedAmount }}</span>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <span class="text-subtitle-1"><b>Data inicial:</b> {{ new Date(object.initialTime).toLocaleDateString() }}</span>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <span class="text-subtitle-1"><b>Data final:</b> {{ new Date(object.finalTime).toLocaleDateString() }}</span>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <span class="text-subtitle-1"><b>Valor total:</b> R${{ object.totalAmount }}</span>
                        </v-col>


                        <v-col cols="12" v-if="object.services && object.services.length > 0" class="mb-4">
                            <v-simple-table dense style="border: 1px solid #dbcaca;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Serviço
                                            </th>
                                            <th class="text-left">
                                                Valor
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in object.services" :key="index">
                                            <td>{{ item.name }}</td>
                                            <td>R$ {{ item.amount }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>



                        <span v-if="object.pictures_before && object.pictures_before.length > 0">Fotos (antes)</span>
                        <v-col cols="12" v-if="object.pictures_before && object.pictures_before.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_before" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_before; lightBoxIndex = index">
                                    </v-img>
                                </div>
                            </div>
                        </v-col>


                        <span v-if="object.pictures_during && object.pictures_during.length > 0">Fotos (durante)</span>
                        <v-col cols="12" v-if="object.pictures_during && object.pictures_during.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_during" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_during; lightBoxIndex = index">
                                    </v-img>
                                </div>
                            </div>
                        </v-col>

                        <span v-if="object.pictures_after && object.pictures_after.length > 0">Fotos (depois)</span>
                        <v-col cols="12" v-if="object.pictures_after && object.pictures_after.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_after" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_after; lightBoxIndex = index">
                                    </v-img>
                                </div>
                            </div>
                        </v-col>

                        <span v-if="object.pictures_nfs && object.pictures_nfs.length > 0">Fotos de Notas Fiscais</span>
                        <v-col cols="12" v-if="object.pictures_nfs && object.pictures_nfs.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_nfs" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_nfs; lightBoxIndex = index">
                                    </v-img>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6" v-if="object.location && object.location.lat">
                            <v-btn color="primary" block @click.native="openMap(object.location)">Ver Localização</v-btn>
                        </v-col>

                        <span v-if="object.obs" class="mt-4"><b>Observações:</b> {{ object.obs }}</span>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="dialogView = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>

        <v-dialog fullscreen v-model="dialog2">
            <v-card v-if="objects" id="printMe2">
                <v-card-title class="text-h5 center-text">
                    Detalhamento de relatório geral
                </v-card-title>
                <v-card-text>
                    <div class="mt-4 mb-4 center-text" v-if="used_filter">
                        <div class="center-text">
                            <h3>Filtros utilizados</h3>
                        </div>
                        <div class="center-text" v-if="used_filter.initialTime && used_filter.initialTime['$gte']">
                            <b>Data inicial:</b> {{ new Date(used_filter.initialTime['$gte']).toLocaleDateString() }}
                        </div>

                        <div class="center-text" v-if="used_filter.initialTime && used_filter.initialTime['$lt']">
                            <b>Data final:</b> {{ new Date(used_filter.initialTime['$lt']).toLocaleDateString() }}
                        </div>

                        <div class="center-text" v-if="used_filter.location_id">
                            <b>Localidade:</b> {{ this.locations.find(l => l._id === used_filter.location_id).name }}
                        </div>
                        <div class="center-text" v-if="used_filter.section_id">
                            <b>Localidade:</b> {{ this.sections.find(s => s._id === used_filter.section_id).location.name }}
                            <b>Seção:</b> {{ this.sections.find(s => s._id === used_filter.section_id).name }}
                        </div>
                    </div>

                    <div class="mt-4 mb-4 center-text" v-if="!used_filter">
                        <h3>Não foram utilizados filtros neste relatório.</h3>
                    </div>

                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Localidade
                                    </th>
                                    <th class="text-left">
                                        Seção
                                    </th>
                                    <th class="text-left">
                                        Código da Loja
                                    </th>
                                    <th class="text-left">
                                        Valor Apurado
                                    </th>
                                    <th class="text-left">
                                        Valor Total
                                    </th>
                                    <th class="text-left">
                                        Data Inicial
                                    </th>
                                    <th class="text-left">
                                        Data Final
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in objects" :key="item.date">
                                    <td>{{ item.section_id.location.name }}</td>
                                    <td>{{ item.section_id.name }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>R${{ item.calculatedAmount ? item.calculatedAmount.toFixed(2) : '0' }}</td>
                                    <td>R${{ item.totalAmount ? item.totalAmount.toFixed(2) : '0' }}</td>
                                    <td>{{ item.initialTime ? new Date(item.initialTime).toLocaleDateString() : '' }}</td>
                                    <td>{{ item.finalTime ? new Date(item.finalTime).toLocaleDateString() : '' }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="mt-4">
                        <b>Valor total R${{ getTotal(objects).toFixed(2) }}</b>
                    </div>

                    <div class="mt-4">
                        Relatório gerado em {{ new Date().toLocaleString() }}
                    </div>
                </v-card-text>
                <v-card-actions class="d-print-none">
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4 mr-2" color="primary" @click="$htmlToPaper('printMe2');">
                        Imprimir
                    </v-btn>
                    <v-btn class="mt-4" color="error" @click="dialog2 = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "RenovationView",

    data() {
        return {
            objects: [],
            object: {},
            user: {},
            sections: [],
            filtered_sections: [],
            locations: [],
            dialog1: false,
            dialog2: false,
            dialogView: false,
            search: '',
            noDataText: 'Sem dados',
            modalInitialDate: false,
            modalFinalDate: false,
            initialDate: '',
            initialDateFormatted: '',
            finalDate: '',
            finalDateFormatted: '',
            objectInitialTimeFormatted: '',
            objectFinalTimeFormatted: '',
            filter: {},
            used_filter: {},
            lightBoxItems: [],
            lightBoxIndex: null,

            headers: [
                { text: "Localidade", value: "section_id.location.name" },
                { text: "Seção", value: "section_id.name" },
                { text: "Código", value: "code" },
                { text: "Valor apurado", value: "calculatedAmount" },
                { text: "Valor total", value: "totalAmount" },
                { text: "Data inicial", value: "initialTime" },
                { text: "Data final", value: "finalTime" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true,
            loadingFilter: false,
        };
    },

    async mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.loading = true;
            this.user = await Api.getRemoteUser();
            let filter = { filed: { $ne: true } };

            if (!this.user.admin) {
                filter.section = { $in: this.user.sections }
            }

            let resp;

            if (this.user.admin) {
                resp = await Api.getSections();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.sections = resp.message;
                }
            }
            else {
                this.sections = this.user.sections;
            }

            if (this.user.admin) {
                resp = await Api.getLocations();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.locations = resp.message;
                }
            }
            else {
                let locations = [];
                this.user.sections.forEach((sec) => {
                    if (!locations.find((l) => l._id === sec.location._id)) locations.push(sec.location);
                });

                this.locations = locations;
            }


            this.filtered_sections = this.sections;
            this.used_filter = undefined;
            this.loading = false;
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        async filterData() {
            this.loadingFilter = true;
            let _filter = { filed: { $ne: true } };

            if (this.initialDate) _filter.initialTime = { $gte: moment(this.initialDate).startOf('day').toDate() };
            if (this.finalDate) {
                if (!_filter.initialTime) _filter.initialTime = {};
                _filter.initialTime['$lt'] = moment(this.finalDate).endOf('day').toDate();
            }

            if (this.filter.code == null || this.filter.code == "") delete this.filter.code;

            if (!this.user.admin) {
                if (!this.filter.user && !this.filter.section_id && !this.filter.location_id) {
                    this.filter.section_id = { $in: this.user.sections };
                }
            }

            let complete_filter = Object.assign(this.filter, _filter);

            this.used_filter = complete_filter;

            let resp = await Api.getRenovationsByFilter(complete_filter, 0);

            if (resp && resp.message) {
                this.loadingFilter = false;
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
            }
            this.loadingFilter = false;
        },

        clearFilterData() {
            this.filter = {};
            this.initialDate = '';
            this.initialDateFormatted = '';
            this.finalDate = '';
            this.finalDateFormatted = '';
            this.init();
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
            }
        },

        openMap(location) {
            if (location && location.lat && location.long) {
                window.open(`https://www.google.com/maps/search/${location.lat},${location.long}`, '_blank').focus();
            }
            else {
                emitToastr('info', 'Sem dados de localização para este registro.')
            }
        },

        getTotal(list) {
            let total = 0;
            list.forEach(i => {
                if (i.totalAmount) {
                    total += i.totalAmount
                }
            });
            return total;
        }

    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.service-list {
    max-height: 50vh;
    overflow-y: auto;
}

.pictures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.image-container {
    display: flex;
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin: 5px;
    margin-left: 15px;
}

.image-thumb {
    width: 100%;
    height: 100%;
}

.remove-image-badge {
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  