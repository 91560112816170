<template>
    <div class="menu-page">
        <h1>Veículo</h1>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="card"></v-skeleton-loader>
                <v-card v-if="!loading" class="mb-4">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="locations" v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_sections" v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto" @change="changeSection"> </v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="filtered_users" v-model="filter.user" item-text="name" item-value="_id" label="Usuário" outlined hide-details="auto"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="openNew">Novo veículo</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="filtered_objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.section`]="{ item }">
                            {{ getSectionName(item.section) }}
                        </template>

                        <template v-slot:[`item.users`]="{ item }">
                            {{ getUserName(item.users) }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="720">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" dense :items="vehicleTypes" v-model="object.type" label="Tipo de veículo" outlined hide-details="auto" v-on:change="changeConsumption"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.consumption" label="Consumo (km/l)" outlined disabled hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" dense :items="fuels" item-text="name" item-value="_id" v-model="object.fuel" label="Combustível" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" item-text="name" item-value="_id" dense :items="sections" v-model="object.section" label="Seção" outlined hide-details="auto">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.location.name }} - {{ data.item.name }}
                                    </v-chip>

                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.location.name }} - {{ data.item.name }}
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" item-text="name" item-value="_id" dense :items="users" v-model="object.users" label="Usuários" multiple outlined hide-details="auto">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.name }}
                                    </v-chip>

                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.brand" label="Marca" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.model" label="Modelo" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" type="number" dense v-model="object.averageKm" label="Média km diário" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" type="number" dense v-model="object.lastKm" label="Km atual" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.plate" label="Placa" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-switch class="mt-1" v-model="object.active" inset label="Ativo?" hide-details="auto"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar veículo
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o veículo <b> {{ object.brand }} {{ object.model }} de placa {{ object.plate }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "VeiculoView",

    data() {
        return {
            objects: [],
            filtered_objects: [],
            locations: [],
            filtered_sections: [],
            filtered_users: [],
            sections: [],
            fuels: [],
            user: {},
            users: [],
            filter: {},
            used_filter: {},
            dialogCreateUpdateObject: false,
            dialog: false,
            search: '',
            noDataText: 'Sem dados',
            object: {},
            headers: [
                { text: "Tipo", value: "type" },
                { text: "Seção", value: "section" },
                { text: "Usuário(s)", value: "users" },
                { text: "Marca", value: "brand" },
                { text: "Modelo", value: "model" },
                { text: "Placa", value: "plate" },
                { text: "Ações", value: "actions" },
            ],
            vehicleTypes: ['Carro', 'Moto'],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.loading = true;
            let resp;
            const user_call = await Api.getRemoteUser();
            const fuel_call = await Api.getFuels();
            if (user_call && user_call._id) this.user = user_call;
            this.fuels = fuel_call.message;

            resp = await Api.getVehicles({ region: true });
            if (resp && resp.message) {
                this.objects = resp.message;
                //this.filtered_objects = resp.message;
            }

            if (this.user.admin) {
                resp = await Api.getSections();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.sections = resp.message;
                }
            }
            else {
                this.sections = this.user.sections;
            }

            if (this.user.admin) {
                resp = await Api.getLocations();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.locations = resp.message;
                }
            }
            else {
                let locations = [];
                this.user.sections.forEach((sec) => {
                    if (!locations.find((l) => l._id === sec.location._id)) locations.push(sec.location);
                });

                this.locations = locations;
            }


            if (this.user.admin) {
                resp = await Api.getUsers();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.users = resp.message;
                }
            }
            else {
                resp = await Api.getUsers({ region: true });
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.users = resp.message;
                    this.filtered_users = resp.message;
                }
            }

            this.filtered_sections = this.sections;
            this.used_filter = undefined;


            this.loading = false;
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateVehicle(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.object = {};
            this.dialogCreateUpdateObject = true;
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
                delete this.filter.user;
            }
        },

        changeSection() {
            if (this.filter.section_id) {
                this.filtered_users = this.users.filter(u => u.sections.find(s => s._id == this.filter.section_id));
                delete this.filter.user;
            }
        },

        changeConsumption() {
            if (this.object.type === 'Carro') this.object.consumption = 10;
            if (this.object.type === 'Moto') this.object.consumption = 25;
        },

        getSectionName(section_id) {
            const section = this.sections.find(s => s._id === section_id);
            return section && section.name || 'NÃO ENCONTRADO!'
        },

        getUserName(users) {
            let usuarios = '';
            usuarios += users.map(u => ' ' + u.name);
            return usuarios;
        },

        filterData() {
            if (this.filter.user) {
                this.filtered_objects = this.objects.filter(v => v.users.find(u => u._id == this.filter.user));
                return;
            }

            if (this.filter.section_id) {
                this.filtered_objects = this.objects.filter(v => v.section == this.filter.section_id);
                return;
            }

            if (this.filter.location_id) {
                let location_sections = [];
                this.sections.forEach(s => {
                    if (s.location._id == this.filter.location_id) location_sections.push(s._id);
                });

                this.filtered_objects = this.objects.filter(v => location_sections.includes(v.section));
                return;
            }

            this.filtered_objects = this.objects;
            return;
        },

        clearFilterData() {
            this.filter = {};
            this.filtered_objects = this.objects;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  