<template>
    <div class="login_container">
        <v-img contain class="logo" src="@/assets/logo.png"></v-img>
        <v-card class="login_card" elevation="4">
            <v-card-title>Entrar</v-card-title>
            <v-card-subtitle>Entre com suas informações de login</v-card-subtitle>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="email" label="Login" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" type="password" label="Senha" outlined hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-row>
                    <v-col cols="12">
                        <v-btn class="mb-2" block large color="primary" @click.native="login">
                            Entrar
                        </v-btn>
                    </v-col>
                </v-row>


            </v-card-actions>
        </v-card>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",

    data() {
        return {
            email: "",
            password: "",
            remember: true,
        };
    },

    components: {},

    mounted() {
        const token = Api.getToken();
        if (token) this.$router.push("/dashboard");
    },

    methods: {
        async login() {
            const resp = await Api.login(this.email, this.password, this.remember);
            if (resp && resp.login_ok) {
                this.$root.$refs.global.updateUser(resp.user);
                this.$router.push("/dashboard");
                return;
            }

            if (resp && resp.error && resp.message) {
                emitToastr('error', resp.message)
                return;
            }
        },

    },
};
</script>
  
<style scoped>
.login_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(145deg, #000000 0%, #08080f 10%, #111127 30%, #1d388b 96%);
}

.logo {
    max-width: 400px;
    max-height: 250px;
    margin-top: 40px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
    .login_container {
        text-align: center;
        padding: 8%;
        padding-top: 2%;
    }

    .logo {
        margin-top: 20px;
        max-width: 90vw;
        max-height: 150px;
    }

}
</style>
  