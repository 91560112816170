<template>
    <div class="menu-page">
        <h1>Relatório de Registro Diário</h1>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" height="130" type="card"></v-skeleton-loader>
                <v-card class="mb-4" v-if="!loading">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="locations" clearable v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_sections" clearable v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto" @change="changeSection"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_users" clearable v-model="filter.user" item-text="name" item-value="_id" label="Usuário" outlined hide-details="auto"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormatted" label="Data final" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>

                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="button-new" color="primary" dark v-bind="attrs" v-on="on">
                                    Relatório para impressão
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item style="cursor: pointer;" @click.native="grouped = true; dialog2 = true">
                                    <v-list-item-title>Com agrupamento</v-list-item-title>
                                </v-list-item>
                                <v-list-item style="cursor: pointer;" @click.native="grouped = false; dialog2 = true">
                                    <v-list-item-title>Sem agrupamento</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading || loadingFilter" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading && !loadingFilter" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.totalPayment`]="{ item }">
                            R${{ item.totalPayment ? item.totalPayment.toFixed(2) : '0' }}
                        </template>

                        <template v-slot:[`item.totalKm`]="{ item }">
                            {{ item.totalKm ? item.totalKm.toFixed(1) : 0 }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="selectedObject = item.index; dialog = true" v-bind="attrs" v-on="on"> mdi-eye-outline</v-icon>
                                    </template>
                                    <span>Ver detalhes</span>
                                </v-tooltip>

                            </div>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </div>

        <v-dialog fullscreen v-model="dialog">
            <v-card v-if="objects[selectedObject]" id="printMe1">
                <v-card-title class="text-h5 center-text">
                    Detalhamento de relatório do dia {{ objects[selectedObject].date }}
                </v-card-title>
                <v-card-text>

                    <div class="mt-4 mb-4 center-text" v-if="used_filter && (used_filter.location_id || used_filter.section_id || used_filter.user)">
                        <div class="center-text">
                            <h3>Filtros utilizados</h3>
                        </div>
                        <div class="center-text" v-if="used_filter.location_id">
                            <b>Localidade:</b> {{ this.locations.find(l => l._id === used_filter.location_id).name }}
                        </div>
                        <div class="center-text" v-if="used_filter.section_id">
                            <b>Localidade:</b> {{ this.sections.find(s => s._id === used_filter.section_id).location.name }}
                            <b>Seção:</b> {{ this.sections.find(s => s._id === used_filter.section_id).name }}
                        </div>
                        <div class="center-text" v-if="used_filter.user">
                            <b>Usuário:</b> {{ this.users.find(u => u._id === used_filter.user).name }} ({{ this.users.find(u => u._id === used_filter.user).email }})
                        </div>
                    </div>

                    <div class="mt-4 mb-4 center-text" v-if="!used_filter">
                        <h3>Não foram utilizados filtros neste relatório.</h3>
                    </div>

                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Início
                                    </th>
                                    <th class="text-left">
                                        Usuário
                                    </th>
                                    <th class="text-left">
                                        Veículo
                                    </th>
                                    <th class="text-left">
                                        Observação inicial
                                    </th>
                                    <th class="text-left">
                                        Observação final
                                    </th>
                                    <th class="text-left">
                                        Km Total
                                    </th>
                                    <th class="text-left">
                                        Valor Total
                                    </th>
                                    <th class="text-left">
                                        Horário Finalização
                                    </th>
                                    <th class="text-left d-print-none">
                                        Foto inicial
                                    </th>
                                    <th class="text-left d-print-none">
                                        Foto final
                                    </th>
                                    <th class="text-left d-print-none">
                                        Pos inicial
                                    </th>
                                    <th class="text-left d-print-none">
                                        Pos final
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in objects[selectedObject].list" :key="item._id">
                                    <td>{{ new Date(item.initialTime).toLocaleString() }}</td>
                                    <td>{{ item.user.name }}</td>
                                    <td>{{ item.vehicle.model }} ({{ item.vehicle.plate }})</td>
                                    <td>{{ item.initialObs }}</td>
                                    <td>{{ item.finished ? item.finalObs : 'RD aberto' }}</td>
                                    <td>{{ item.finished ? item.totalKm.toFixed(1) : 0 }}</td>
                                    <td>R${{ item.totalPayment ? item.totalPayment.toFixed(2) : 0 }}</td>
                                    <td>{{ item.finished ? new Date(item.finalTime).toLocaleString() : '' }}</td>
                                    <td class="d-print-none"><v-btn icon @click.native="openImage(item.initialImage)"><v-icon>mdi-image-outline</v-icon></v-btn></td>
                                    <td class="d-print-none"><v-btn icon @click.native="openImage(item.finalImage)" v-if="item.finished"><v-icon>mdi-image-outline</v-icon></v-btn></td>
                                    <td v-if="item.initialLocation" class="d-print-none"><v-btn icon @click.native="openMap(item.initialLocation)"><v-icon>mdi-map-outline</v-icon></v-btn></td>
                                    <td v-if="item.finalLocation" class="d-print-none"><v-btn icon @click.native="openMap(item.finalLocation)" v-if="item.finished"><v-icon>mdi-map-outline</v-icon></v-btn></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div class="mt-4">
                        <b>Valor total R${{ objects[selectedObject].totalPayment ? objects[selectedObject].totalPayment.toFixed(2) : '0.00' }}</b>
                    </div>

                    <div class="mt-4">
                        Relatório gerado em {{ new Date().toLocaleString() }}
                    </div>
                </v-card-text>
                <v-card-actions class="d-print-none">
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4 mr-2" color="primary" @click="$htmlToPaper('printMe1');">
                        Imprimir
                    </v-btn>
                    <v-btn class="mt-4" color="error" @click="dialog = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog fullscreen v-model="dialog2">
            <v-card v-if="objects" id="printMe2">
                <v-card-title class="text-h5 center-text">
                    Detalhamento de relatório geral
                </v-card-title>
                <v-card-text>
                    <div class="mt-4 mb-4 center-text" v-if="used_filter">
                        <div class="center-text">
                            <h3>Filtros utilizados</h3>
                        </div>
                        <div class="center-text" v-if="used_filter.initialTime && used_filter.initialTime['$gte']">
                            <b>Data inicial:</b> {{ new Date(used_filter.initialTime['$gte']).toLocaleDateString() }}
                        </div>

                        <div class="center-text" v-if="used_filter.initialTime && used_filter.initialTime['$lt']">
                            <b>Data final:</b> {{ new Date(used_filter.initialTime['$lt']).toLocaleDateString() }}
                        </div>

                        <div class="center-text" v-if="used_filter.location_id">
                            <b>Localidade:</b> {{ this.locations.find(l => l._id === used_filter.location_id).name }}
                        </div>
                        <div class="center-text" v-if="used_filter.section_id">
                            <b>Localidade:</b> {{ this.sections.find(s => s._id === used_filter.section_id).location.name }}
                            <b>Seção:</b> {{ this.sections.find(s => s._id === used_filter.section_id).name }}
                        </div>
                        <div class="center-text" v-if="used_filter.user">
                            <b>Usuário:</b> {{ this.users.find(u => u._id === used_filter.user).name }} ({{ this.users.find(u => u._id === used_filter.user).email }})
                        </div>
                    </div>

                    <div class="mt-4 mb-4 center-text" v-if="!used_filter">
                        <h3>Não foram utilizados filtros neste relatório.</h3>
                    </div>

                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Data
                                    </th>
                                    <th class="text-left" v-if="grouped == true">
                                        Localidade
                                    </th>
                                    <th class="text-left" v-if="grouped == true">
                                        Seção
                                    </th>
                                    <th class="text-left" v-if="grouped == false">
                                        Km inicial
                                    </th>
                                    <th class="text-left" v-if="grouped == false">
                                        Km final
                                    </th>
                                    <th class="text-left">
                                        Km total
                                    </th>
                                    <th class="text-left">
                                        Valor Total
                                    </th>
                                    <th class="text-left" v-if="grouped == true">
                                        Registros
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="grouped == true">
                                <tr v-for="item in objects" :key="item.date">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.location }}</td>
                                    <td>{{ item.section }}</td>
                                    <td>{{ item.totalKm ? item.totalKm.toFixed(1) : 0 }}</td>
                                    <td>R$ {{ item.totalPayment ? item.totalPayment.toFixed(2) : 0 }}</td>
                                    <td>{{ item.totalRecords }}</td>
                                </tr>
                            </tbody>

                            <tbody v-if="grouped == false">
                                <tr v-for="item in not_grouped_objects" :key="item.date">
                                    <td>{{ new Date(item.created).toLocaleDateString() }}</td>
                                    <td>{{ item.initialKm }}</td>
                                    <td>{{ item.finalKm }}</td>
                                    <td>{{ item.totalKm ? item.totalKm.toFixed(1) : 0 }}</td>
                                    <td>R$ {{ item.totalPayment ? item.totalPayment.toFixed(2) : 0 }}</td>
                                </tr>
                            </tbody>

                        </template>
                    </v-simple-table>

                    <div class="mt-4">
                        <b>Valor total R${{ getTotal(objects).toFixed(2) }}</b>
                    </div>

                    <div class="mt-4">
                        Relatório gerado em {{ new Date().toLocaleString() }}
                    </div>
                </v-card-text>
                <v-card-actions class="d-print-none">
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4 mr-2" color="primary" @click="$htmlToPaper('printMe2');">
                        Imprimir
                    </v-btn>
                    <v-btn class="mt-4" color="error" @click="dialog2 = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "RelatórioRDView",

    data() {
        return {
            grouped: true,
            objects: [],
            not_grouped_objects: [],
            selectedObject: 0,
            locations: [],
            sections: [],
            filtered_sections: [],
            users: [],
            filtered_users: [],
            user: {},
            filter: {},
            used_filter: {},
            dialogCreateUpdateObject: false,
            dialog: false,
            dialog2: false,
            search: '',
            noDataText: 'Sem dados',
            object: {},
            modalInitialDate: false,
            modalFinalDate: false,
            initialDate: '',
            initialDateFormatted: '',
            finalDate: '',
            finalDateFormatted: '',
            headers: [
                { text: "Data", value: "date" },
                { text: "Localidade", value: "location" },
                { text: "Seção", value: "section" },
                { text: "Km Total", value: "totalKm" },
                { text: "Valor Total", value: "totalPayment" },
                { text: "Registros", value: "totalRecords" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true,
            loadingFilter: false,
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            this.user = await Api.getRemoteUser();
            //VERIFICANDO PERMISSÃO
            if (!this.user.admin) {
                const profile = this.user.profile;
                if (!(profile.report_rd && profile.report_rd.read)) {
                    emitToastr("error", "Permissões insuficientes.");
                    this.$router.push("/dashboard");
                }
            }

            const resp = await Api.startGetReport();

            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }

                this.used_filter = undefined;
                this.locations = resp.message.locations;
                this.sections = resp.message.sections;
                this.filtered_sections = resp.message.sections;
                this.users = resp.message.users;
                this.filtered_users = resp.message.users;
            }

            this.loading = false;
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateLocation(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        clearFilterData() {
            this.filter = {};
            this.initialDate = '';
            this.initialDateFormatted = '';
            this.finalDate = '';
            this.finalDateFormatted = '';
            this.init();
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
                delete this.filter.user;
            }
        },

        changeSection() {
            if (this.filter.section_id) {
                this.filtered_users = this.users.filter(u => u.sections.find(s => s._id == this.filter.section_id));
                delete this.filter.user;
            }
        },

        async filterData() {
            this.loadingFilter = true;
            let _filter = { filed: { $ne: true } };

            if (this.initialDate) _filter.initialTime = { $gte: moment(this.initialDate).startOf('day').toDate() };
            if (this.finalDate) {
                if (!_filter.initialTime) _filter.initialTime = {};
                _filter.initialTime['$lt'] = moment(this.finalDate).endOf('day').toDate();
            }

            if (!this.user.admin) {
                if (!this.filter.user && !this.filter.section_id && !this.filter.location_id) {
                    this.filter.section_id = { $in: this.user.sections };
                }
            }

            if (this.filter.location_id === null) delete this.filter.location_id;
            if (this.filter.section_id === null) delete this.filter.section_id;
            if (this.filter.user === null) delete this.filter.user;

            let complete_filter = Object.assign(this.filter, _filter);

            this.used_filter = complete_filter;

            let resp = await Api.getDailyRecordsByFilter(complete_filter, 0)

            if (resp && resp.message) {
                this.loadingFilter = false;
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = this.organizeObjects(resp.message);
                this.not_grouped_objects = resp.message;
            }
            this.loadingFilter = false;
        },

        organizeObjects(objects) {
            let objects_list = [];

            objects.forEach(obj => {
                let date = new Date(obj.initialTime).toLocaleDateString();
                const object_index = objects_list.findIndex(o => o.date === date);

                if (object_index == -1) {
                    objects_list.push({ date: date, location: '', section: '', totalKm: 0, totalPayment: 0, totalRecords: 0, list: [obj] })
                }
                else {
                    objects_list[object_index].list.push(obj);
                }
            })

            objects_list.forEach((list_item, index) => {
                let totalKm = 0;
                let totalPayment = 0;
                let locations = [];
                let sections = [];

                list_item.list.forEach(daily_record => {
                    if (daily_record.finished) {
                        totalKm += daily_record.totalKm;
                        totalPayment += daily_record.totalPayment;
                    }

                    if (!locations.includes(daily_record.section_id.location.name)) locations.push(daily_record.section_id.location.name);
                    if (!sections.includes(daily_record.section_id.name)) sections.push(daily_record.section_id.name);
                });

                list_item.index = index;
                list_item.totalKm = totalKm;
                list_item.totalPayment = totalPayment;
                list_item.totalRecords = list_item.list.length;
                list_item.location = locations.length > 1 ? 'Mais de uma localidade' : locations[0];
                list_item.section = sections.length > 1 ? 'Mais de uma seção' : sections[0];
            });

            return objects_list;
        },

        openMap(location) {
            if (location && location.lat && location.long) {
                window.open(`https://www.google.com/maps/search/${location.lat},${location.long}`, '_blank').focus();
            }
            else {
                emitToastr('info', 'Sem dados de localização para este registro.')
            }
        },

        openImage(image) {
            window.open(image, '_blank').focus();
        },

        getTotal(list) {
            let total = 0;
            list.forEach(i => total += i.totalPayment);
            return total;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.center-text {
    text-align: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  