<template>
  <v-app style="background-color: #eaeaea">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar" v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app>
        <center class="avatar_container mt-6 mb-6">
          <div class="avatar">
            <img src="@/assets/logo.png" width="70" />
          </div>
          <h5 class="icon">{{ user && user.name ? user.name : projectName }}</h5>
        </center>
        <v-divider />

        <v-list dense v-if="user">

          <v-list v-if="hasRegisterPermission()">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title class="menu-item">Cadastro</v-list-item-title>
              </template>
              <template v-slot:prependIcon>
                <v-icon class="icon">mdi-plus-box-multiple-outline</v-icon>
              </template>

              <router-link to="/localidade" v-if="user.admin || (user.profile && user.profile.location && user.profile.location.read)">
                <v-list-item>
                  <v-list-item-icon class="item_icon">
                    <v-icon class="icon">mdi-city-variant-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Localidade</v-list-item-title>
                </v-list-item>
              </router-link>


              <router-link to="/secao" v-if="user.admin || (user.profile && user.profile.section && user.profile.section.read)">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-map-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Seção</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/perfil" v-if="user.admin">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-account-edit</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Perfil</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/usuario" v-if="user.admin">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-account</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Usuário</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/combustivel" v-if="user.admin || (user.profile && user.profile.fuel && user.profile.fuel.read)">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-gas-station</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Combustível</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/veiculo" v-if="user.admin || (user.profile && user.profile.vehicle && user.profile.vehicle.read)">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-car</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Veículo</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/checklists" v-if="user.admin || (user.profile && user.profile.checklists && user.profile.checklists.read)">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-clipboard-check-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Checklists de Auditoria</v-list-item-title>
                </v-list-item>
              </router-link>

            </v-list-group>
          </v-list>


          <router-link to="/registro-diario" v-if="user.admin || (user.profile && user.profile.daily_record && user.profile.daily_record.read)">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-car-clock</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Registro Diário</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>


          <router-link to="/reforma-lojas" v-if="user.admin || (user.profile && user.profile.store_renovation && user.profile.store_renovation.read)">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-store-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Reforma de Lojas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/auditoria" v-if="user.admin || (user.profile && user.profile.audit && user.profile.audit.read)">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-store-search-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Auditoria</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list v-if="hasReportPermission()">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title class="menu-item">Relatórios</v-list-item-title>
              </template>
              <template v-slot:prependIcon>
                <v-icon class="icon">mdi-file-chart-outline</v-icon>
              </template>

              <router-link to="/relatorios" v-if="user.admin || (user.profile && user.profile.report_rd && user.profile.report_rd.read)">
                <v-list-item link>
                  <v-list-item-action class="item_icon">
                    <v-icon class="icon">mdi-car-clock</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="menu-item">Registro Diário</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link to="/auditoria-individual" v-if="user.admin || (user.profile && user.profile.report_individual_audit && user.profile.report_individual_audit.read)">
                <v-list-item link>
                  <v-list-item-action class="item_icon">
                    <v-icon class="icon">mdi-account-alert</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="menu-item">Auditoria Individual</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link to="/auditoria-relatorio" v-if="user.admin || (user.profile && user.profile.report_audit && user.profile.report_audit.read)">
                <v-list-item link>
                  <v-list-item-action class="item_icon">
                    <v-icon class="icon">mdi-store-search-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="menu-item">Auditoria</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link to="/auditoria-relatorio-visita" v-if="user.admin || (user.profile && user.profile.report_visits && user.profile.report_visits.read)">
                <v-list-item link>
                  <v-list-item-action class="item_icon">
                    <v-icon class="icon">mdi-store-search-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="menu-item">Visitas de Auditoria</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link to="/reformas-relatorio" v-if="user.admin || (user.profile && user.profile.report_renovations && user.profile.report_renovations.read)">
                <v-list-item link>
                  <v-list-item-action class="item_icon">
                    <v-icon class="icon">mdi-store-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="menu-item">Reformas</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list-group>
          </v-list>

        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-app-bar color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" dark elevation="2">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
          <v-toolbar-title class="toolbar">
            {{ projectName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu>
            <template #activator="{ on: menu }">
              <v-btn icon v-on="menu">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <router-link to="/minha-conta">
                <v-list-item>
                  <v-list-item-title class="bar-menu-item-title">
                    <v-icon class="mr-3">mdi-account</v-icon>
                    Minha conta
                  </v-list-item-title>
                </v-list-item>
              </router-link>

              <v-list-item @click="logout()">
                <v-list-item-title class="bar-menu-item-title">
                  <v-icon class="mr-3">mdi-exit-run</v-icon>
                  Sair
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container fluid class="menu-page-container">
          <router-view fluid />
          <div class="footer-version">
            <h5>Ges360 - v1.05</h5>
          </div>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    showLoading: false,
    projectName: "GES360",
    user: { admin: false }
  }),

  methods: {
    logout() {
      Api.logout();
    },

    setLoading(loading) {
      this.showLoading = loading;
    },

    updateUser(user) {
      this.user = user;
    },

    hasReportPermission() {
      if (this.user && this.user.admin) return true;
      if (!this.user || !this.user.profile) return false;

      let permission = false;
      const profile = this.user.profile;

      if (profile.report_rd && profile.report_rd.read) permission = true;
      if (profile.report_individual_audit && profile.report_individual_audit.read) permission = true;
      if (profile.report_audit && profile.report_audit.read) permission = true;
      if (profile.report_visits && profile.report_visits.read) permission = true;
      if (profile.report_renovations && profile.report_renovations.read) permission = true;

      return permission;
    },

    hasRegisterPermission() {
      if (this.user && this.user.admin) return true;
      if (!this.user || !this.user.profile) return false;

      let permission = false;
      const profile = this.user.profile;

      if (profile.location && profile.location.read) permission = true;
      if (profile.section && profile.section.read) permission = true;
      if (profile.fuel && profile.fuel.read) permission = true;
      if (profile.vehicle && profile.vehicle.read) permission = true;
      if (profile.checklists && profile.checklists.read) permission = true;

      return permission;
    }
  },

  created() {
    this.$root.$refs.global = this;
  },

  async mounted() {
    this.user = Api.getUser();

    if (this.user && this.user._id) this.user = await Api.getRemoteUser();
    this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.bar-menu-item-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-page-container {
  background-color: #eaeaea;
}

.toolbar {
  font-weight: 700;
  color: black;
}

.drawer {
  background: rgb(0, 0, 0);
  background: linear-gradient(145deg, #000000 0%, #08080f 10%, #111127 30%, #1d388b 96%);
  z-index: 100;
}

.icon {
  color: rgb(118, 161, 255);
}

.item_icon {
  margin-right: 16px !important;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-item {
  color: white;
  font-weight: 700 !important;
  text-overflow: none !important;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.footer-version {
  width: 100%;
  text-align: center;
  color: #8d8d8d;
}
</style>
