<template>
    <div class="menu-page">
        <h1>Auditoria Individual</h1>

        <CoolLightBox :items="lightBoxItems || []" :index="lightBoxIndex" @close="lightBoxIndex = null"></CoolLightBox>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" height="130" type="card"></v-skeleton-loader>
                <v-card class="mb-4" v-if="!loading">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="locations" clearable v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_sections" clearable v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto" @change="changeSection"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_users" clearable v-model="filter.user" item-text="name" item-value="_id" label="Usuário" outlined hide-details="auto"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormatted" label="Data final" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col cols="12" v-if="objects.length == 0">
                Sem resultados
            </v-col>
            <v-col cols="12" v-if="objects.length > 0">
                <v-card class="mb-2" v-for="(item, index) in objects" :key="index">
                    <v-card-title>{{ new Date(item.initialTime).toLocaleDateString() }}</v-card-title>
                    <v-card-subtitle v-if="item.finished"><span class="mr-1 mt-2" style="font-size: 16px; font-weight: 600;">R${{ item.totalPayment }}</span>({{ item.totalKm }} km)</v-card-subtitle>
                    <v-card-subtitle v-if="!item.finished">RD aberto</v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" lg="6" md="6" sm="6">
                                <div class="item-container">
                                    <div><b>Km inicial: </b>{{ item.initialKm }}</div>
                                    <div><b>Horário inicial: </b>{{ new Date(item.initialTime).toLocaleString() }}</div>
                                    <div v-if="item.initialLocation" class="mb-2"><v-btn class="primary" @click.native="openMap(item.initialLocation)">Localização</v-btn></div>
                                    <div>
                                        <v-img contain :src="item.initialImage" @click.native="lightBoxItems = [item.initialImage]; lightBoxIndex = 0"></v-img>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="6" v-if="item.finished">
                                <div class="item-container">
                                    <div><b>Km final: </b>{{ item.finalKm }}</div>
                                    <div><b>Horário final: </b>{{ new Date(item.finalTime).toLocaleString() }}</div>
                                    <div v-if="item.finished == 'system'">Finalizado pelo sistema</div>
                                    <div v-if="item.finalLocation" class="mb-2"><v-btn class="primary" @click.native="openMap(item.finalLocation)">Localização</v-btn></div>
                                    <div>
                                        <v-img contain :src="item.finalImage" @click.native="lightBoxItems = [item.finalImage]; lightBoxIndex = 0"></v-img>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="6" v-if="!item.finished">
                                <h3>RD Aberto</h3>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </div>

    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "RelatórioRDView",

    data() {
        return {
            objects: [],
            selectedObject: 0,
            locations: [],
            sections: [],
            filtered_sections: [],
            users: [],
            filtered_users: [],
            user: {},
            filter: {},
            used_filter: {},
            dialog: false,
            dialog2: false,
            noDataText: 'Sem dados',
            object: {},
            modalInitialDate: false,
            modalFinalDate: false,
            initialDate: '',
            initialDateFormatted: '',
            finalDate: '',
            finalDateFormatted: '',
            loading: true,
            lightBoxItems: [],
            lightBoxIndex: null,
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            this.user = await Api.getRemoteUser();
            const resp = await Api.startGetReport();

            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }

                this.used_filter = undefined;
                this.locations = resp.message.locations;
                this.sections = resp.message.sections;
                this.filtered_sections = resp.message.sections;
                this.users = resp.message.users;
                this.filtered_users = resp.message.users;
            }

            this.loading = false;
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        clearFilterData() {
            this.filter = {};
            this.initialDate = '';
            this.initialDateFormatted = '';
            this.finalDate = '';
            this.finalDateFormatted = '';
            this.objects = [];
            this.init();
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
                delete this.filter.user;
            }
        },

        changeSection() {
            if (this.filter.section_id) {
                this.filtered_users = this.users.filter(u => u.sections.find(s => s._id == this.filter.section_id));
                delete this.filter.user;
            }
        },

        async filterData() {
            if (!this.filter.user) return emitToastr("error", "É necessário selecionar um usuário para iniciar essa auditoria.");
            this.loading = true;

            let _filter = { filed: { $ne: true } };

            if (this.initialDate) _filter.initialTime = { $gte: moment(this.initialDate).startOf('day').toDate() };
            if (this.finalDate) {
                if (!_filter.initialTime) _filter.initialTime = {};
                _filter.initialTime['$lt'] = moment(this.finalDate).endOf('day').toDate();
            }

            if (!this.user.admin) {
                if (!this.filter.user && !this.filter.section_id && !this.filter.location_id) {
                    this.filter.section_id = { $in: this.user.sections };
                }
            }

            if (this.filter.location_id === null) delete this.filter.location_id;
            if (this.filter.section_id === null) delete this.filter.section_id;
            if (this.filter.user === null) delete this.filter.user;

            let complete_filter = Object.assign(this.filter, _filter);

            this.used_filter = complete_filter;

            let resp = await Api.getDailyRecordsByFilter(complete_filter, 0)

            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
                this.objects = this.objects.sort((a, b) => new Date(a.created) - new Date(b.created));
            }
            this.loading = false;
        },

        openMap(location) {
            if (location && location.lat && location.long) {
                window.open(`https://www.google.com/maps/search/${location.lat},${location.long}`, '_blank').focus();
            }
            else {
                emitToastr('info', 'Sem dados de localização para este registro.')
            }
        },

        openImage(image) {
            window.open(image, '_blank').focus();
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.center-text {
    text-align: center;
    justify-content: center;
}

.item-container {
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  