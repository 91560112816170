<template>
    <div class="menu-page">
        <h1>Usuário</h1>

        <div>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="openNew">Novo usuário</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">


                        <template v-slot:[`item.active`]="{ item }">
                            {{ item.active ? 'Sim' : 'Não' }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; formatObjectSections(); dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>


                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.name" label="Nome do usuário" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete v-model="object.sections" :items="sections" dense outlined clearable chips multiple color="blue-grey lighten-2" label="Seções" item-text="name" item-value="_id" no-data-text="Sem dados" hide-details="auto">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.location.name }} - {{ data.item.name }}
                                    </v-chip>

                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.location.name }} - {{ data.item.name }}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete v-model="object.rd_section" :items="sections.filter(s => object.sections && object.sections.includes(s._id))" dense outlined clearable color="blue-grey lighten-2" label="Seção de RD" item-text="name" item-value="_id" no-data-text="Sem dados"
                                hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete :no-data-text="noDataText" dense :items="profiles" v-model="object.profile" item-text="name" item-value="_id" label="Perfil" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.email" label="Login do usuário (minúsculas)" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.password" label="Senha" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.telegram_id" label="ID do Telegram" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="object.rd_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense outlined v-model="object.rd_time" label="Horário fechamento RD" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-time-picker v-if="menu2" format="24hr" autocomplete="nope" v-model="object.rd_time" label="Horário fechamento RD" hide-details="auto" @click:minute="$refs.menu.save(object.rd_time)"></v-time-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <div class="switches">
                                <v-switch class="mt-1" v-model="object.active" inset label="Ativo?" hide-details="auto"></v-switch>
                                <v-switch class="ml-6 mt-1" v-model="object.admin" inset label="Administrador?" hide-details="auto"></v-switch>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="primary" v-if="object.telegram_id" @click.native="testTelegram(object.telegram_id)" :loading="buttonLoading">Testar Telegram</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar usuário
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o usuário <b>{{ object.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "UsuarioView",

    data() {
        return {
            objects: [],
            sections: [],
            profiles: [],
            dialogCreateUpdateObject: false,
            dialog: false,
            search: '',
            noDataText: 'Sem dados',
            object: {},
            menu2: false,
            headers: [
                { text: "Nome", value: "name" },
                { text: "Login", value: "email" },
                { text: "Perfil", value: "profile.name" },
                { text: "Ativo", value: "active" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            let resp = await Api.getSections();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.sections = resp.message;
            }

            resp = await Api.getProfiles();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.profiles = resp.message;
            }

            resp = await Api.getUsers();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }

            this.loading = false;
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateUser(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        testTelegram(telegram_id) {
            Api.testTelegram({ telegram_id: telegram_id })
        },

        formatObjectSections() {
            //let sections = [];
            if (this.object.sections && this.object.sections.length > 0) {
                for (let i in this.object.sections) {
                    if (typeof (this.object.sections[i]) == 'object') this.object.sections[i] = this.object.sections[i]._id;
                }
            }
        },

        openNew() {
            this.object = {};
            this.dialogCreateUpdateObject = true;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.switches {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  