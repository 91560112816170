import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Auditoria from "../views/Auditoria.vue";
import AuditoriaRelatorio from "../views/AuditoriaRelatorio.vue";
import AuditoriaIndividual from "../views/AuditoriaIndividual.vue";
import AuditoriaRelatorioVisita from "../views/AuditoriaRelatorioVisita.vue";
import Combustivel from "../views/Combustivel.vue";
import Localidade from "../views/Localidade.vue";
import Perfil from "../views/Perfil.vue";
import MinhaConta from "../views/MinhaConta.vue";
import Reforma from "../views/Reforma.vue";
import ReformaRelatorio from "../views/ReformaRelatorio.vue";
import RegistroDiario from "../views/RegistroDiario.vue";
import Relatorios from "../views/Relatorios.vue";
import Secao from "../views/Secao.vue";
import Usuario from "../views/Usuario.vue";
import Veiculo from "../views/Veiculo.vue";
import Checklist from "../views/Checklist.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/auditoria",
    name: "Auditoria",
    component: Auditoria,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/auditoria-relatorio",
    name: "Auditoria Relatório",
    component: AuditoriaRelatorio,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/auditoria-individual",
    name: "Auditoria Individual",
    component: AuditoriaIndividual,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/auditoria-relatorio-visita",
    name: "Auditoria Relatório",
    component: AuditoriaRelatorioVisita,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/reformas-relatorio",
    name: "Reforma Relatório",
    component: ReformaRelatorio,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/combustivel",
    name: "Combustivel",
    component: Combustivel,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/localidade",
    name: "Localidade",
    component: Localidade,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: Perfil,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/minha-conta",
    name: "Minha Conta",
    component: MinhaConta,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/reforma-lojas",
    name: "Reforma",
    component: Reforma,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/registro-diario",
    name: "RegistroDiario",
    component: RegistroDiario,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: Relatorios,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/secao",
    name: "Secao",
    component: Secao,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/usuario",
    name: "Usuario",
    component: Usuario,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/veiculo",
    name: "Veiculo",
    component: Veiculo,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/checklists",
    name: "Checklists",
    component: Checklist,
    meta: {
      layout: "menu",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/", "/password-recovery"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
