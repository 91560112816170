<template>
    <div class="menu-page">
        <h1>Relatório de Auditoria</h1>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" height="130" type="card"></v-skeleton-loader>
                <v-card v-if="!loading" class="mb-4">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="locations" v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="filtered_sections" v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto" @change="changeSection"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="filtered_users" v-model="filter.user" item-text="name" item-value="_id" label="Usuário" outlined hide-details="auto"></v-autocomplete>
                            </v-col>


                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormatted" label="Data final" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="dialog2 = true">Relatório para impressão</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.date`]="{ item }">
                            {{ item.formattedDate }}
                        </template>

                        <template v-slot:[`item.section_id`]="{ item }">
                            {{ getSection(item.section_id) }}
                        </template>

                        <template v-slot:[`item.location_id`]="{ item }">
                            {{ getLocation(item.location_id) }}
                        </template>

                        <template v-slot:[`item.finished`]="{ item }">
                            {{ item.finished ? 'Concluído' : checkDelay(item.date) }}
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog fullscreen v-model="dialog2">
            <v-card v-if="objects" id="printMe2">
                <v-card-title class="text-h5 center-text">
                    Detalhamento de relatório geral
                </v-card-title>
                <v-card-text>
                    <div class="mt-4 mb-4 center-text" v-if="used_filter">
                        <div class="center-text">
                            <h3>Filtros utilizados</h3>
                        </div>
                        <div class="center-text" v-if="used_filter.date && used_filter.date['$gte']">
                            <b>Data:</b> {{ new Date(used_filter.date['$gte']).toLocaleDateString() }}
                        </div>

                        <div class="center-text" v-if="used_filter.date && used_filter.date['$lt']">
                            <b>Data final:</b> {{ new Date(used_filter.date['$lt']).toLocaleDateString() }}
                        </div>

                        <div class="center-text" v-if="used_filter.location_id">
                            <b>Localidade:</b> {{ this.locations.find(l => l._id === used_filter.location_id).name }}
                        </div>
                        <div class="center-text" v-if="used_filter.section_id">
                            <b>Localidade:</b> {{ this.sections.find(s => s._id === used_filter.section_id).location.name }}
                            <b>Seção:</b> {{ this.sections.find(s => s._id === used_filter.section_id).name }}
                        </div>
                    </div>

                    <div class="mt-4 mb-4 center-text" v-if="!used_filter">
                        <h3>Não foram utilizados filtros neste relatório.</h3>
                    </div>

                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Data
                                    </th>
                                    <th class="text-left">
                                        Auditor
                                    </th>
                                    <th class="text-left">
                                        Código da Loja
                                    </th>
                                    <th class="text-left">
                                        Localidade
                                    </th>
                                    <th class="text-left">
                                        Seção
                                    </th>
                                    <th class="text-left">
                                        Status
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in objects" :key="item.date">
                                    <td>{{ item.date ? new Date(item.date).toLocaleDateString() : '' }}</td>
                                    <td>{{ item.user.name }}</td>
                                    <td>{{ item.store_code }}</td>
                                    <td>{{ getSection(item.section_id) }}</td>
                                    <td>{{ getLocation(item.location_id) }}</td>
                                    <td>{{ item.finished ? 'Concluído' : checkDelay(item.date) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div class="mt-4">
                        Relatório gerado em {{ new Date().toLocaleString() }}
                    </div>
                </v-card-text>
                <v-card-actions class="d-print-none">
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4 mr-2" color="primary" @click="$htmlToPaper('printMe2');">
                        Imprimir
                    </v-btn>
                    <v-btn class="mt-4" color="error" @click="dialog2 = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "RenovationView",

    data() {
        return {
            objects: [],
            object: { services: [] },
            user: {},
            sections: [],
            users: [],
            filtered_users: [],
            filtered_sections: [],
            locations: [],
            dialog2: false,
            search: '',
            noDataText: 'Sem dados',
            modalInitialDate: false,
            modalFinalDate: false,
            initialDate: '',
            initialDateFormatted: '',
            finalDate: '',
            finalDateFormatted: '',
            filter: {},
            used_filter: {},

            headers: [
                { text: "Data", value: "formattedDate" },
                { text: "Código da Loja", value: "store_code" },
                { text: "Localidade", value: "location_id" },
                { text: "Seção", value: "section_id" },
                { text: "Auditor", value: "user.name" },
                { text: "Status", value: "finished" }
            ],
            buttonLoading: false,
            loading: true
        };
    },

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            this.user = await Api.getRemoteUser();
            let filter = { filed: { $ne: true } };

            let resp;

            if (!this.user.admin) {
                filter.section = { $in: this.user.sections }
            }

            if (this.user.admin) {
                resp = await Api.getSections();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.sections = resp.message;
                }
            }
            else {
                this.sections = this.user.sections;
            }

            if (this.user.admin) {
                resp = await Api.getLocations();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.locations = resp.message;
                }
            }
            else {
                let locations = [];
                this.user.sections.forEach((sec) => {
                    if (!locations.find((l) => l._id === sec.location._id)) locations.push(sec.location);
                });

                this.locations = locations;
            }

            if (this.user.admin) {
                resp = await Api.getUsers();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.users = resp.message;
                }
            }
            else {
                resp = await Api.getUsers({ region: true });
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.users = resp.message;
                    this.filtered_users = resp.message;
                }
            }

            this.filtered_sections = this.sections;
            this.used_filter = undefined;
            this.loading = false;
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        async filterData() {
            let _filter = { filed: { $ne: true } };

            if (this.initialDate) _filter.date = { $gte: moment(this.initialDate).startOf('day').subtract({hours: 3}).toDate() };
            if (this.finalDate) {
                if (!_filter.date) _filter.date = {};
                _filter.date['$lt'] = moment(this.finalDate).endOf('day').subtract({hours: 3}).toDate();
            }

            if (!this.user.admin) {
                if (!this.filter.user && !this.filter.section_id && !this.filter.location_id) {
                    this.filter.section_id = { $in: this.user.sections };
                }
            }

            if (this.filter.location_id === null) delete this.filter.location_id;
            if (this.filter.section_id === null) delete this.filter.section_id;
            if (this.filter.user === null) delete this.filter.user;

            let complete_filter = Object.assign(this.filter, _filter);

            this.used_filter = complete_filter;

            let resp = await Api.getAuditsByFilter(complete_filter);

            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;

                this.objects.forEach(o => {
                    o.formattedDate = this.formatDateTable(o.date);
                })
            }
        },

        clearFilterData() {
            this.filter = {};
            this.initialDate = '';
            this.initialDateFormatted = '';
            this.finalDate = '';
            this.finalDateFormatted = '';
            this.init();
        },

        getSection(id) {
            const section = this.sections.find(s => s._id === id);
            if (section && section.name) return section.name;
        },

        getLocation(id) {
            const location = this.locations.find(l => l._id === id);
            if (location && location.name) return location.name;
        },

        formatDateTable(date) {
            const dt = date.split('T')[0];
            return `${dt.split('-')[2]}/${dt.split('-')[1]}/${dt.split('-')[0]}`;
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
                delete this.filter.user;
            }
        },

        changeSection() {
            if (this.filter.section_id) {
                this.filtered_users = this.users.filter(u => u.sections.find(s => s._id == this.filter.section_id));
                delete this.filter.user;
            }
        },

        checkDelay(data) {
            const today = moment(new Date()).startOf('day').toDate();
            const date = moment(new Date(data).getTime() + 12 * 60 * 60 * 1000).endOf('day').toDate();

            if (today > date) {
                return 'Em atraso'
            }
            else {
                return 'A fazer'
            }
        }

    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.service-list {
    max-height: 50vh;
    overflow-y: auto;
}

.pictures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.image-container {
    display: flex;
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin: 5px;
    margin-left: 15px;
}

.image-thumb {
    width: 100%;
    height: 100%;
}

.remove-image-badge {
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  