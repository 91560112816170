<template>
    <div class="menu-page">
        <h1>Reforma de Lojas</h1>

        <CoolLightBox :items="lightBoxItems || []" :index="lightBoxIndex" @close="lightBoxIndex = null"></CoolLightBox>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" height="130" type="card"></v-skeleton-loader>
                <v-card class="mb-4" v-if="!loading">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="locations" v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" dense :items="filtered_sections" v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-text-field dense outlined hide-details="auto" v-model="filter.code" label="Código da loja"></v-text-field>
                            </v-col>


                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormatted" label="Data final" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>


            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="openNew">Nova reforma</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="editObject(item)" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                        <template v-slot:[`item.initialTime`]="{ item }">
                            {{ item.initialTime ? new Date(item.initialTime).toLocaleDateString() : '' }}
                        </template>

                        <template v-slot:[`item.finalTime`]="{ item }">
                            {{ item.finalTime ? new Date(item.finalTime).toLocaleDateString() : '' }}
                        </template>

                        <template v-slot:[`item.totalAmount`]="{ item }">
                            R${{ item.totalAmount }}
                        </template>

                        <template v-slot:[`item.calculatedAmount`]="{ item }">
                            R${{ item.calculatedAmount }}
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete :no-data-text="noDataText" item-text="name" item-value="_id" dense :items="sections" v-model="object.section_id" label="Seção" outlined hide-details="auto">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.location.name }} - {{ data.item.name }}
                                    </v-chip>

                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.location.name }} - {{ data.item.name }}
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.code" label="Código" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.calculatedAmount" label="Valor apurado" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" v-mask="'##/##/####'" dense v-model="objectInitialTimeFormatted" label="Data inicial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" v-mask="'##/##/####'" dense v-model="objectFinalTimeFormatted" label="Data final" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" disabled autocomplete="nope" dense v-model="object.totalAmount" label="Valor total" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-file-input accept="image/*" v-model="file1" @change="uploadImage($event, 'before')" label="Fotos (antes)" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-file-input accept="image/*" v-model="file2" @change="uploadImage($event, 'during')" label="Fotos (durante)" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-file-input accept="image/*" v-model="file3" @change="uploadImage($event, 'after')" label="Fotos (depois)" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-file-input accept="image/*" v-model="file4" @change="uploadImage($event, 'nfs')" label="Fotos de Notas Fiscais" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <span v-if="object.pictures_before && object.pictures_before.length > 0">Fotos (antes)</span>
                        <v-col cols="12" v-if="object.pictures_before && object.pictures_before.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_before" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_before; lightBoxIndex = index">
                                    </v-img>
                                    <v-badge color="red" class="remove-image-badge" icon="mdi-close-thick" @click.native="object.pictures_before.splice(index, 1)"></v-badge>
                                </div>
                            </div>
                        </v-col>


                        <span v-if="object.pictures_during && object.pictures_during.length > 0">Fotos (durante)</span>
                        <v-col cols="12" v-if="object.pictures_during && object.pictures_during.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_during" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_during; lightBoxIndex = index">
                                    </v-img>
                                    <v-badge color="red" class="remove-image-badge" icon="mdi-close-thick" @click.native="object.pictures_during.splice(index, 1)"></v-badge>
                                </div>
                            </div>
                        </v-col>

                        <span v-if="object.pictures_after && object.pictures_after.length > 0">Fotos (depois)</span>
                        <v-col cols="12" v-if="object.pictures_after && object.pictures_after.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_after" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_after; lightBoxIndex = index">
                                    </v-img>
                                    <v-badge color="red" class="remove-image-badge" icon="mdi-close-thick" @click.native="object.pictures_after.splice(index, 1)"></v-badge>
                                </div>
                            </div>
                        </v-col>

                        <span v-if="object.pictures_nfs && object.pictures_nfs.length > 0">Fotos de Notas Fiscais</span>
                        <v-col cols="12" v-if="object.pictures_nfs && object.pictures_nfs.length > 0">
                            <div class="pictures-container">
                                <div class="image-container" v-for="(pic, index) in object.pictures_nfs" :key="index">
                                    <v-img class="image-thumb" contain :src="pic" @click.native="lightBoxItems = object.pictures_nfs; lightBoxIndex = index">
                                    </v-img>
                                    <v-badge color="red" class="remove-image-badge" icon="mdi-close-thick" @click.native="object.pictures_nfs.splice(index, 1)"></v-badge>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-btn color="primary" block @click.native="getUserLocation">Coletar Localização</v-btn>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6" v-if="object.location && object.location.lat">
                            <v-btn color="primary" block @click.native="openMap(object.location)">Ver Localização</v-btn>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-btn color="primary" block @click.native="servicesDialog = true">Ver ou editar serviços</v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea rows="5" outlined v-model="object.obs" label="Observações" placeholder="Observaçoes"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>

        <v-dialog v-model="servicesDialog" max-width="600">
            <v-card v-if="servicesDialog">
                <v-card-title class="text-h5">
                    Serviços
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-list dense v-if="object.services" class="service-list">
                                <v-subheader>SERVIÇOS</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item v-for="(item, i) in object.services || []" :key="i">
                                        <v-list-item-icon>
                                            <v-icon>mdi-account-hard-hat-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.name }} - R$ {{ item.amount }}
                                                <v-btn icon class="ml-2" small color="error" @click.stop="object.services.splice(i, 1); calcTotalAmount(); $forceUpdate()">
                                                    <v-icon small>mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field autocomplete="nope" dense v-model="serviceName" label="Nome do serviço" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field type="number" autocomplete="nope" dense v-model="serviceAmount" label="Valor do serviço" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-btn color="success" @click.native="addService">Adicionar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="servicesDialog = false">
                        Pronto
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar reforma
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a reforma <b>{{ object.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "RenovationView",

    data() {
        return {
            objects: [],
            object: { services: [] },
            user: {},
            sections: [],
            filtered_sections: [],
            locations: [],
            dialogCreateUpdateObject: false,
            dialog: false,
            servicesDialog: false,
            search: '',
            noDataText: 'Sem dados',
            serviceName: '',
            serviceAmount: 0,
            modalInitialDate: false,
            modalFinalDate: false,
            initialDate: '',
            initialDateFormatted: '',
            finalDate: '',
            finalDateFormatted: '',
            objectInitialTimeFormatted: '',
            objectFinalTimeFormatted: '',
            file1: undefined,
            file2: undefined,
            file3: undefined,
            file4: undefined,
            lightBoxItems: [],
            lightBoxIndex: null,
            fileInputMessage: 'Aceita várias fotos',
            filter: {},
            used_filter: {},
            headers: [
                { text: "Localidade", value: "section_id.location.name" },
                { text: "Seção", value: "section_id.name" },
                { text: "Código", value: "code" },
                { text: "Valor apurado", value: "calculatedAmount" },
                { text: "Valor total", value: "totalAmount" },
                { text: "Data inicial", value: "initialTime" },
                { text: "Data final", value: "finalTime" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            this.user = await Api.getRemoteUser();
            let filter = { filed: { $ne: true } };
            let resp;

            if (!this.user.admin) {
                filter.section_id = { $in: this.user.sections }
            }

            if (this.user.admin) {
                resp = await Api.getSections();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.sections = resp.message;
                }
            }
            else {
                this.sections = this.user.sections;
            }

            if (this.user.admin) {
                resp = await Api.getLocations();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.locations = resp.message;
                }
            }
            else {
                let locations = [];
                this.user.sections.forEach((sec) => {
                    if (!locations.find((l) => l._id === sec.location._id)) locations.push(sec.location);
                });

                this.locations = locations;
            }


            resp = await Api.getRenovationsByFilter(filter, 0);
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }

            this.loading = false;
        },

        async createOrUpdateObject() {
            if (this.objectInitialTimeFormatted) this.object.initialTime = `${this.objectInitialTimeFormatted.split('/')[2]}-${this.objectInitialTimeFormatted.split('/')[1]}-${this.objectInitialTimeFormatted.split('/')[0]} 12:00:00`;
            if (this.objectFinalTimeFormatted) this.object.finalTime = `${this.objectFinalTimeFormatted.split('/')[2]}-${this.objectFinalTimeFormatted.split('/')[1]}-${this.objectFinalTimeFormatted.split('/')[0]} 12:00:00`;

            this.buttonLoading = true;
            const resp = await Api.createOrUpdateRenovation(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.objectInitialTimeFormatted = '';
            this.objectFinalTimeFormatted = '';
            this.object = {};
            this.dialogCreateUpdateObject = true;
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        async filterByDate() {
            if (!this.initialDate || !this.finalDate) return emitToastr('error', "Data inicial e data final obrigatórias.");
            this.loading = true;


            let resp = await Api.getRenovationsByFilter({ filed: { $ne: true }, initialTime: { $gte: moment(this.initialDate).startOf('day').toDate() }, finalTime: { $lt: moment(this.finalDate).endOf('day').toDate() } });
            if (resp && resp.message) {
                this.loading = false;
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
            }

            this.loading = false;
        },

        addService() {
            if (!this.serviceName) return emitToastr('error', "Nome do serviço obrigatório.");
            if (!this.serviceAmount) return emitToastr('error', "Valor do serviço obrigatório.");

            if (!this.object.services) this.object.services = [];
            this.object.services.push({ name: this.serviceName, amount: Number(this.serviceAmount) });
            this.serviceName = '';
            this.serviceAmount = 0;

            this.calcTotalAmount();
        },

        calcTotalAmount() {
            let totalAmount = 0;
            for (let i in this.object.services) {
                totalAmount += this.object.services[i].amount
            }

            this.object.totalAmount = totalAmount;
        },

        editObject(item) {
            this.object = item;
            if (this.object.initialTime) this.objectInitialTimeFormatted = moment(this.object.initialTime).format("DD/MM/YYYY");
            if (this.object.finalTime) this.objectFinalTimeFormatted = moment(this.object.finalTime).format("DD/MM/YYYY");
            this.dialogCreateUpdateObject = true
        },


        locationSuccess(pos) {
            this.object.location = { lat: pos.coords.latitude, long: pos.coords.longitude, acc: pos.coords.accuracy };
            this.$forceUpdate();
        },

        locationError(err) {
            this.object.location = err;
        },

        getUserLocation() {
            navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError);
        },

        openMap(location) {
            if (location && location.lat && location.long) {
                window.open(`https://www.google.com/maps/search/${location.lat},${location.long}`, '_blank').focus();
            }
            else {
                emitToastr('info', 'Sem dados de localização para este registro.')
            }
        },

        async filterData() {
            this.loadingFilter = true;
            let _filter = { filed: { $ne: true } };

            if (this.initialDate) _filter.initialTime = { $gte: moment(this.initialDate).startOf('day').toDate() };
            if (this.finalDate) {
                if (!_filter.initialTime) _filter.initialTime = {};
                _filter.initialTime['$lt'] = moment(this.finalDate).endOf('day').toDate();
            }

            if (this.filter.code == null || this.filter.code == "") delete this.filter.code;

            if (!this.user.admin) {
                if (!this.filter.user && !this.filter.section_id && !this.filter.location_id) {
                    this.filter.section_id = { $in: this.user.sections };
                }
            }

            let complete_filter = Object.assign(this.filter, _filter);

            this.used_filter = complete_filter;

            let resp = await Api.getRenovationsByFilter(complete_filter, 0);

            if (resp && resp.message) {
                this.loadingFilter = false;
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
            }
            this.loadingFilter = false;
        },

        clearFilterData() {
            this.filter = {};
            this.initialDate = '';
            this.initialDateFormatted = '';
            this.finalDate = '';
            this.finalDateFormatted = '';
            this.init();
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
            }
        },

        async uploadImage(data, type = 'before') {
            if (data) {
                this.buttonLoading = true;
                this.fileInputMessage = "Comprimindo e salvando a imagem...";
                const resp = await Api.uploadImage(data, false);

                this.buttonLoading = false;

                if (resp && resp.message) {
                    if (!this.object.pictures_before) this.object.pictures_before = [];
                    if (!this.object.pictures_during) this.object.pictures_during = [];
                    if (!this.object.pictures_after) this.object.pictures_after = [];
                    if (!this.object.pictures_nfs) this.object.pictures_nfs = [];

                    if (type === 'before') this.object.pictures_before.push({ type: 'image', src: resp.message });
                    if (type === 'during') this.object.pictures_during.push({ type: 'image', src: resp.message });
                    if (type === 'after') this.object.pictures_after.push({ type: 'image', src: resp.message });
                    if (type === 'nfs') this.object.pictures_nfs.push({ type: 'image', src: resp.message });
                }

                this.fileInputMessage = "Aceita várias fotos";
                this.file = null;
            }
        },
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.service-list {
    max-height: 50vh;
    overflow-y: auto;
}

.pictures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.image-container {
    display: flex;
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin: 5px;
    margin-left: 15px;
}

.image-thumb {
    width: 100%;
    height: 100%;
}

.remove-image-badge {
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  