<template>
    <div class="menu-page">
        <h1>Auditoria</h1>

        <div>
            <v-col cols="12">
                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" height="130" type="card"></v-skeleton-loader>
                <v-card v-if="!loading" class="mb-4">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="locations" v-model="filter.location_id" item-text="name" item-value="_id" label="Localidade" outlined hide-details="auto" @change="changeLocation"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="filtered_sections" v-model="filter.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto" @change="changeSection"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-autocomplete :no-data-text="noDataText" clearable dense :items="filtered_users" v-model="filter.user" item-text="name" item-value="_id" label="Usuário" outlined hide-details="auto"></v-autocomplete>
                            </v-col>


                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDateFilter" :return-value.sync="initialDateFilter" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormattedFilter" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDateFilter" scrollable v-on:input="dateSelectedFilter('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDateFilter = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDateFilter); modalInitialDateFilter = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDateFilter" :return-value.sync="finalDateFilter" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormattedFilter" label="Data final" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDateFilter" scrollable v-on:input=" dateSelectedFilter('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDateFilter = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDateFilter); modalFinalDateFilter = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="6">
                                <v-btn class="mr-2" color="error" @click.native="clearFilterData">Limpar filtro</v-btn>
                                <v-btn color="primary" @click.native="filterData">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="openNew">Nova auditoria</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.date`]="{ item }">
                            {{ item.formattedDate }}
                        </template>

                        <template v-slot:[`item.section_id`]="{ item }">
                            {{ getSection(item.section_id) }}
                        </template>

                        <template v-slot:[`item.location_id`]="{ item }">
                            {{ getLocation(item.location_id) }}
                        </template>

                        <template v-slot:[`item.finished`]="{ item }">
                            {{ item.finished ? 'Concluído' : checkDelay(item.date) }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; formatDate(); dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="1200">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>

                    <v-row class="mb-4">
                        <v-col cols="12" sm="6" v-if="users">
                            <v-autocomplete :no-data-text="noDataText" dense :items="users" v-model="object.user" item-text="name" item-value="_id" label="Auditor" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" dense :items="sections" v-model="object.section_id" item-text="name" item-value="_id" label="Seção" outlined hide-details="auto" @change="getSectionData">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.location.name }} - {{ data.item.name }}
                                    </v-chip>

                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.location.name }} - {{ data.item.name }}
                                </template>
                            </v-autocomplete>
                        </v-col>


                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.store_code" label="Código da loja" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modalInitialDate = false">
                                        Cancelar
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Formulário
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card outlined tile>
                                            <v-card-subtitle><b>Informações da loja</b></v-card-subtitle>
                                            <div class="pa-2">
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field autocomplete="nope" dense v-model="object.cambista_name" label="Nome do cambista" outlined hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-text-field autocomplete="nope" v-mask="'###.###.###-##'" dense v-model="object.cambista_cpf" label="CPF do cambista" outlined hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-text-field autocomplete="nope" dense v-mask="'##/##/####'" v-model="object.hiring_date" label="Data de contratação" outlined hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-autocomplete :no-data-text="noDataText" dense :items="storeTypes" v-model="object.type" label="Tipo" outlined hide-details="auto"></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-switch class="mt-1" v-model="object.receives_rent" inset label="Recebe aluguel?" hide-details="auto"></v-switch>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-text-field type="number" autocomplete="nope" dense v-model="object.rentAmount" label="Valor do aluguel" :disabled="!object.receives_rent" outlined hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-text-field type="number" autocomplete="nope" dense v-model="object.soldLast10" label="Venda da última dezena" outlined hide-details="auto"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-card outlined tile>
                                            <v-card-subtitle><b>Coleta de localização</b></v-card-subtitle>
                                            <div class="pa-2">
                                                <v-row>
                                                    <v-col cols="12" lg="4" md="4" sm="6">
                                                        <v-btn color="primary" block @click.native="getUserLocation">Coletar Localização</v-btn>
                                                    </v-col>

                                                    <v-col cols="12" lg="4" md="4" sm="6" v-if="object.location && object.location.lat">
                                                        <v-btn color="primary" block @click.native="openMap(object.location)">Ver Localização</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12">

                                    </v-col>



                                    <v-col cols="12">
                                        <v-card outlined tile>
                                            <v-card-subtitle><b>Checklist</b></v-card-subtitle>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-if="!object._id" :no-data-text="noDataText" dense :items="checklists" item-text="name" item-value="_id" label="Selecione o checklist" outlined hide-details="auto" @change="changeChecklist($event)"></v-autocomplete>
                                            </v-col>

                                            <div class="pa-2">
                                                <v-row>
                                                    <v-col cols="12" sm="6" v-for="(item, index) in object.checklist" :key="index">
                                                        <v-radio-group v-model="object.checklist[index].resp" v-if="item.type == 'radio'">
                                                            <span>
                                                                {{ item.label }}
                                                                <v-badge v-if="user.admin || !item.protected" color="red" class="ml-4" icon="mdi-close-thick" @click.native="selectedChecklistItem = index; dialog2 = true"></v-badge>
                                                            </span>

                                                            <v-radio label="Sim" value="Sim"></v-radio>
                                                            <v-radio color="error" label="Não" value="Não"></v-radio>
                                                        </v-radio-group>

                                                        <v-text-field v-if="item.type == 'text'" autocomplete="nope" dense v-model="object.checklist[index].resp" :label="item.label" outlined hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12">
                                                        <v-textarea v-model="object.auditor_obs" outlined label="Observações do auditor"></v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-col cols="12" sm="6">
                        <v-switch class="mt-1" v-model="object.finished" inset label="Auditoria concluída" hide-details="auto"></v-switch>
                    </v-col>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar auditoria
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a auditoria <b>{{ object.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar item de checklist
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar esse item do checklist?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog2 = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog2 = false; object.checklist.splice(selectedChecklistItem, 1)">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr, validarCPF } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "AuditoriaView",

    data() {
        return {
            objects: [],
            users: [],
            user: {},
            sections: [],
            locations: [],
            filtered_users: [],
            filtered_sections: [],

            modalInitialDateFilter: false,
            modalFinalDateFilter: false,
            initialDateFilter: '',
            initialDateFormattedFilter: '',
            finalDateFilter: '',
            finalDateFormattedFilter: '',
            objectInitialTimeFormatted: '',
            objectFinalTimeFormatted: '',
            filter: {},
            used_filter: {},


            checklists: [],
            dialogCreateUpdateObject: false,
            modalInitialDate: false,
            initialDate: '',
            selectedChecklistItem: 0,
            initialDateFormatted: '',
            dialog: false,
            dialog2: false,
            itemLabel: '',
            user_sections: [],
            search: '',
            noDataText: 'Sem dados',
            object: { checklist: [] },
            storeTypes: ['Loja', 'PDV', 'Ambulante'],
            headers: [
                { text: "Data", value: "formattedDate" },
                { text: "Código da Loja", value: "store_code" },
                { text: "Localidade", value: "location_id" },
                { text: "Seção", value: "section_id" },
                { text: "Auditor", value: "user.name" },
                { text: "Status", value: "finished", sorte: true },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;

            const user_call = Api.getRemoteUser();
            const users_call = Api.getUsers();
            const checklists_call = Api.getChecklists();

            const promisses = [await user_call, await users_call, await checklists_call]

            this.user = promisses[0];
            this.users = promisses[1];
            this.checklists = promisses[2].message;

            this.users = this.users.message;

            let filter = { filed: { $ne: true } };

            if (!this.user.admin) {
                filter.section_id = { $in: this.user.sections }

                let sections_ids = [];
                this.user.sections.forEach(s => sections_ids.push(s._id));

                //SÓ OS USUÁRIOS DAS SEÇÕES DELE
                this.users = this.users.filter(u => u.sections.some(u2 => sections_ids.includes(u2._id)));
            }

            let resp = await Api.getAuditsByFilter(filter);
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;

                this.objects.forEach(o => {
                    o.formattedDate = this.formatDateTable(o.date);
                })
            }

            if (this.user.admin) {
                resp = await Api.getSections();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.sections = resp.message;
                }
            }
            else {
                this.sections = this.user.sections;
            }

            if (this.user.admin) {
                resp = await Api.getLocations();
                if (resp && resp.message) {
                    if (resp.error) {
                        this.loading = false;
                        return emitToastr('error', resp.message);
                    }
                    this.locations = resp.message;
                }
            }
            else {
                let locations = [];
                this.user.sections.forEach((sec) => {
                    if (!locations.find((l) => l._id === sec.location._id)) locations.push(sec.location);
                });

                this.locations = locations;
            }

            this.loading = false;
        },

        async createOrUpdateObject() {
            if (this.object.cambista_cpf && !validarCPF(this.object.cambista_cpf)) return emitToastr('error', 'CPF do cambista inválido.');
            if (!this.object.date) return emitToastr('error', 'A data é obrigatória.');

            this.buttonLoading = true;
            const resp = await Api.createOrUpdateAudit(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.object = { checklist: [] };
            this.dialogCreateUpdateObject = true;
        },

        dateSelected() {
            const splitedDate = this.initialDate.split('-');
            this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            this.object.date = new Date(this.initialDate);
        },

        formatDate() {
            if (this.object.date && typeof (this.object.date) === 'string') {
                const dt = this.object.date.split('T')[0];
                this.initialDateFormatted = `${dt.split('-')[2]}/${dt.split('-')[1]}/${dt.split('-')[0]}`
            }
        },

        formatDateTable(date) {
            if (!date) return;
            const dt = date.split('T')[0];
            return `${dt.split('-')[2]}/${dt.split('-')[1]}/${dt.split('-')[0]}`;
        },

        changeLocation() {
            if (this.filter.location_id) {
                this.filtered_sections = this.sections.filter(s => s.location._id === this.filter.location_id);
                delete this.filter.user;
            }
        },

        changeSection() {
            if (this.filter.section_id) {
                this.filtered_users = this.users.filter(u => u.sections.find(s => s._id == this.filter.section_id));
                delete this.filter.user;
            }
        },

        async filterData() {
            let _filter = { filed: { $ne: true } };

            console.log(this.initialDateFilter, this.finalDateFilter)

            if (this.initialDateFilter) _filter.date = { $gte: moment(this.initialDateFilter).startOf('day').subtract({hours: 3}).toDate() };
            if (this.finalDateFilter) {
                if (!_filter.date) _filter.date = {};
                _filter.date['$lt'] = moment(this.finalDateFilter).endOf('day').subtract({hours: 3}).toDate();
            }

            if (!this.user.admin) {
                if (!this.filter.user && !this.filter.section_id && !this.filter.location_id) {
                    this.filter.section_id = { $in: this.user.sections };
                }
            }

            if (this.filter.location_id === null) delete this.filter.location_id;
            if (this.filter.section_id === null) delete this.filter.section_id;
            if (this.filter.user === null) delete this.filter.user;

            let complete_filter = Object.assign(this.filter, _filter);

            this.used_filter = complete_filter;

            console.log(complete_filter)

            let resp = await Api.getAuditsByFilter(complete_filter);

            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;

                this.objects.forEach(o => {
                    o.formattedDate = this.formatDateTable(o.date);
                })
            }
        },

        clearFilterData() {
            this.filter = {};
            this.initialDate = '';
            this.initialDateFormatted = '';
            this.finalDate = '';
            this.finalDateFormatted = '';
            this.init();
        },

        dateSelectedFilter(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDateFilter.split('-');
                this.initialDateFormattedFilter = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDateFilter.split('-');
                this.finalDateFormattedFilter = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        locationSuccess(pos) {
            this.object.location = { lat: pos.coords.latitude, long: pos.coords.longitude, acc: pos.coords.accuracy };
            this.$forceUpdate();
        },

        locationError(err) {
            this.object.location = err;
        },

        getUserLocation() {
            navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError);
        },

        openMap(location) {
            if (location && location.lat && location.long) {
                window.open(`https://www.google.com/maps/search/${location.lat},${location.long}`, '_blank').focus();
            }
            else {
                emitToastr('info', 'Sem dados de localização para este registro.')
            }
        },

        changeChecklist(list_id) {
            const checklist = this.checklists.find(x => x._id === list_id)
            this.object.checklist = checklist.tasks;
        },

        getSectionData() {
            const temp_section = this.sections.find(s => s._id === this.object.section_id);
            if (temp_section) this.object.location_id = temp_section.location._id;
        },

        getSection(id) {
            const section = this.sections.find(s => s._id === id);
            if (section && section.name) return section.name;
        },

        getLocation(id) {
            const location = this.locations.find(l => l._id === id);
            if (location && location.name) return location.name;
        },

        checkDelay(data) {
            const today = moment(new Date()).startOf('day').toDate();
            const date = moment(new Date(data).getTime() + 12 * 60 * 60 * 1000).endOf('day').toDate();

            if (today > date) {
                return 'Em atraso'
            }
            else {
                return 'A fazer'
            }
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  