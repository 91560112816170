<template>
    <div class="menu-page">
        <h1>Registro Diário</h1>

        <v-tabs v-model="tab" class="mt-4" background-color="transparent">
            <v-tab>
                Registrar
            </v-tab>

            <v-tab>
                Meus registros
            </v-tab>
        </v-tabs>

        <v-tabs-items class="mt-4" v-model="tab">
            <v-tab-item>
                <v-card flat class="tab-card">
                    <v-card-text>
                        <div>
                            <v-row v-if="!loading">
                                <v-col cols="12">
                                    <v-card>
                                        <v-card-title class="table-header">
                                            <span v-if="vehicles.length < 1">Sem veículos para seu usuário</span>
                                            <v-spacer></v-spacer>
                                            <v-btn class="button-new" color="primary" @click.native="openNew" :disabled="Boolean(active_daily_record) || !Boolean(daily_record_section)">Iniciar registro</v-btn>
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <div class="mt-6" v-if="active_daily_record && active_daily_record._id">
                                <h1 class="mb-6">Registro em andamento</h1>

                                <v-card style="max-width: 600px">
                                    <v-card-title>{{ active_daily_record.user.name }}</v-card-title>
                                    <v-card-text>
                                        <div>
                                            Início: {{ new Date(active_daily_record.initialTime).toLocaleString() }}
                                        </div>
                                        <div>
                                            Km inicial: {{ active_daily_record.initialKm }}
                                        </div>
                                        <div v-if="active_daily_record.initialObs">
                                            Observação: {{ active_daily_record.initialObs }}
                                        </div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" @click.native="finish">Finalizar registro</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat class="tab-card">
                    <v-card-title>

                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="initialDateFormatted" label="Data inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined hide-details="auto" v-model="finalDateFormatted" label="Data final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click=" modalFinalDate = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-btn class="ml-4" color="primary" @click.native="filterByDate">Filtrar</v-btn>
                            </v-col>
                        </v-row>

                    </v-card-title>
                    <v-card-text>
                        <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                        <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :items-per-page="50" class="elevation-1" :footer-props="{
                            'items-per-page-text': 'Linhas por página',
                            'items-per-page-all-text': 'Tudo',
                            'items-per-page-options': [5, 10, 30, 50, 100, -1],
                        }">


                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="actions-container">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium class="mr-4" color="primary" @click.stop="viewObject = item; dialogView = true" v-bind="attrs" v-on="on"> mdi-eye-outline</v-icon>
                                        </template>
                                        <span>Ver</span>
                                    </v-tooltip>
                                </div>
                            </template>

                            <template v-slot:[`item.initialTime`]="{ item }">
                                {{ new Date(item.initialTime).toLocaleString() }}
                            </template>

                            <template v-slot:[`item.finalTime`]="{ item }">
                                {{ new Date(item.finalTime).toLocaleString() }}
                            </template>

                            <template v-slot:[`item.totalPayment`]="{ item }">
                                R${{ item.totalPayment }}
                            </template>

                        </v-data-table>

                        <div class="mt-4" v-if="showSummary">
                            <h3>Km total: {{ totalKm }} (R$ {{ totalPayment }})</h3>
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>


        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" dense :items="vehicles" v-model="object.vehicle" item-text="model" item-value="_id" label="Veículo" outlined hide-details="auto">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.model }} - {{ data.item.plate }}
                                    </v-chip>

                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.model }} - {{ data.item.plate }}
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.initialKm" label="Km inicial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.initialObs" label="Observação inicial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-file-input capture accept="image/*;capture=camera" v-model="file" @change="uploadImage($event, 'initial')" label="Foto do odômetro inicial" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-col cols="12" sm="12">
                            <div class="pictures-container">
                                <img class="image-thumb" contain :src="object.initialImage" />
                            </div>
                            <div v-if="object.initialImage">
                                <center>
                                    <h4 class="mt-1">VERIFIQUE SE A FOTO FICOU NA ORIENTAÇÃO CORRETA ANTES DE SALVAR</h4>
                                </center>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialogCreateUpdateObject2" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject2 = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-autocomplete :no-data-text="noDataText" dense :items="vehicles" v-model="object.vehicle" item-text="model" disabled item-value="_id" label="Veículo" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" disabled dense v-model="object.initialKm" label="Km inicial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense disabled v-model="object.initialObs" label="Observação inicial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.finalKm" label="Km final" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.finalObs" label="Observação final" outlined hide-details="auto"></v-text-field>
                        </v-col>


                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-file-input capture accept="image/*;capture=camera" v-model="file" @change="uploadImage($event, 'final')" label="Foto do odômetro final" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-col cols="12" lg="12" md="12" sm="12">
                            <div class="pictures-container">
                                <img class="image-thumb mr-2 mb-2" contain :src="object.initialImage" />
                                <img class="image-thumb" contain :src="object.finalImage" />
                            </div>
                            <div v-if="object.initialImage">
                                <center>
                                    <h4 class="mt-1">VERIFIQUE SE A FOTO FICOU NA ORIENTAÇÃO CORRETA ANTES DE SALVAR</h4>
                                </center>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="openSaveDialog" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>

        <v-dialog v-model="dialogView" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>Registro</span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogView = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="viewObject.initialKm">
                    <div>
                        <div><b>Data inicial: </b>{{ new Date(viewObject.initialTime).toLocaleString() }}</div>
                        <div><b>Data final: </b>{{ new Date(viewObject.finalTime).toLocaleString() }}</div>
                        <div><b>Km inicial: </b>{{ viewObject.initialKm }}</div>
                        <div><b>Km final: </b>{{ viewObject.finalKm }}</div>
                        <div><b>Total km: </b>{{ viewObject.totalKm }}</div>
                        <div><b>Valor: </b>R$ {{ viewObject.totalPayment }}</div>
                        <div><b>Observação inicial: </b>{{ viewObject.initialObs }}</div>
                        <div><b>Observação final: </b>{{ viewObject.finalObs }}</div>
                        <!--
                        <div class="pictures-container">
                            <img class="image-thumb mr-2 mb-2" contain :src="viewObject.initialImage" />
                            <img class="image-thumb" contain :src="viewObject.finalImage" />
                        </div>
                        -->
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="dialogView = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog v-model="saveDialog" max-width="450">
            <v-card v-if="saveDialog">
                <v-card-title class="text-h5">
                    Finalizar Registro
                </v-card-title>
                <v-card-text>
                    <div class="text-center">Você rodou</div>
                    <div class="text-h4 text-center"><b>{{ (object.finalKm - object.initialKm) }}km</b></div>
                    <div class="mt-2" style="color: red" v-if="object.finalKm - object.initialKm > vehicle.averageKm">
                        Foi observado que o deslocamento de hoje está maior que a média.
                    </div>
                    <div class="mt-2">
                        Depois de salvo, esse registro não poderá ser modificado.
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="saveDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="saveDialog = false; dialogCreateUpdateObject2 = false; createOrUpdateObject()">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');

export default {
    name: "RegistroView",

    data() {
        return {
            tab: '',
            objects: [],
            vehicle: {},
            vehicles: [],
            active_daily_record: false,
            user: {},
            daily_record_section: false,
            dialogCreateUpdateObject: false,
            dialogCreateUpdateObject2: false,
            dialogView: false,
            viewObject: {},
            saveDialog: false,
            fileInputMessage: '',
            file: null,
            dialog: false,
            modalInitialDate: false,
            modalFinalDate: false,
            finalDate: '',
            initialDate: '',
            initialDateFormatted: '',
            finalDateFormatted: '',
            search: '',
            noDataText: 'Sem dados',
            showSummary: false,
            totalPayment: 0,
            totalKm: 0,
            object: {},
            headers: [
                { text: "Data", value: "initialTime" },
                { text: "Km Total", value: "totalKm" },
                { text: "Valor", value: "totalPayment" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            this.user = await Api.getRemoteUser();

            let resp = await Api.getUserVehicles();
            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.vehicles = resp.message;
            }

            resp = await Api.getDailyRecordsByFilter({ user: this.user._id, filed: { $ne: true }, finished: { $exists: false } }, 1);
            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.active_daily_record = resp.message[0];
            }

            this.loading = false;

            resp = await Api.getDailyRecordsByFilter({ user: this.user._id, filed: { $ne: true }, finished: { $exists: true } }, 10);
            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
            }

            if (this.user && this.user.rd_section) {
                this.daily_record_section = this.user.rd_section;
            }
            else {
                return emitToastr('error', 'Usuário sem seção de RD cadastrada.');
            }

        },

        openSaveDialog() {
            if (this.object.initialKm < this.vehicle.lastKm) return emitToastr('error', "Km inicial não pode ser menor do que o km atual do veículo");
            if (this.object.finalKm && this.object.finalKm < this.vehicle.lastKm) return emitToastr('error', "Km final não pode ser menor do que o km atual do veículo");
            if (this.object.finalKm && this.object.finalKm < this.object.initialKm) return emitToastr('error', "Km final não pode ser menor do que o km inicial deste registro.");

            if (!this.object.finalKm || !this.object.finalImage) return emitToastr('error', "Preencha a Km final e envie a foto do odômetro.");

            this.saveDialog = true;
        },

        async createOrUpdateObject() {
            if (!this.object._id) {
                if (!this.object.vehicle) return emitToastr('error', "Veículo precisa estar selecionado");
                this.vehicle = this.vehicles.find(v => v._id === this.object.vehicle);
                if (!this.vehicle) return emitToastr('error', "Veículo não encontrado.");
            }

            if (this.object.initialKm < this.vehicle.lastKm) return emitToastr('error', "Km inicial não pode ser menor do que o km atual do veículo");
            if (this.vehicle.lastKm && (this.object.initialKm - this.vehicle.lastKm > 1000)) return emitToastr('error', "Km inicial maior que 1000km do último valor registrado.");
            if (this.object.finalKm && this.object.finalKm < this.vehicle.lastKm) return emitToastr('error', "Km final não pode ser menor do que o km atual do veículo");
            if (this.object.finalKm && this.object.finalKm < this.object.initialKm) return emitToastr('error', "Km final não pode ser menor do que o km inicial deste registro.");

            this.buttonLoading = true;
            const resp = await Api.createOrUpdateDailyRecord(this.object, this.object.vehicle, this.daily_record_section._id, this.daily_record_section.location._id);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
            this.dialogCreateUpdateObject2 = false;
        },

        async uploadImage(data, type = 'initial') {
            if (data) {
                this.buttonLoading = true;
                this.fileInputMessage = "Comprimindo e salvando a imagem...";
                const resp = await Api.uploadImage(data, true);

                this.buttonLoading = false;

                if (resp && resp.message) {
                    if (type === 'initial') this.object.initialImage = resp.message;
                    if (type === 'final') this.object.finalImage = resp.message;
                }

                this.fileInputMessage = '';
                this.file = null;
            }
        },

        openNew() {
            this.object = {};
            this.getUserLocation('initial');
        },

        finish() {
            this.object = this.active_daily_record;
            this.getUserLocation('final');
        },

        initialLocationSuccess(pos) {
            this.object.initialLocation = { lat: pos.coords.latitude, long: pos.coords.longitude, acc: pos.coords.accuracy };
            this.dialogCreateUpdateObject = true;
            this.$forceUpdate();
        },

        initialLocationError(err) {
            emitToastr("error", "Permita o acesso do navegador à sua localização antes de iniciar um registro diário.");
            this.object.initialLocation = err;
        },

        finalLocationSuccess(pos) {
            this.object.finalLocation = { lat: pos.coords.latitude, long: pos.coords.longitude, acc: pos.coords.accuracy };
            this.dialogCreateUpdateObject2 = true;
            this.$forceUpdate();
        },

        finalLocationError(err) {
            emitToastr("error", "Permita o acesso do navegador à sua localização antes de iniciar um registro diário.");
            this.object.finalLocation = err;
        },

        getUserLocation(type = 'initial') {
            if (type === 'initial') navigator.geolocation.getCurrentPosition(this.initialLocationSuccess, this.initialLocationError);
            if (type === 'final') navigator.geolocation.getCurrentPosition(this.finalLocationSuccess, this.finalLocationError);
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        async filterByDate() {
            if (!this.initialDate || !this.finalDate) return emitToastr('error', "Data inicial e data final obrigatórias.");
            this.loading = true;


            let resp = await Api.getDailyRecordsByFilter({ user: this.user._id, filed: { $ne: true }, finished: { $exists: true }, initialTime: { $gte: moment(this.initialDate).startOf('day').toDate() }, finalTime: { $lt: moment(this.finalDate).endOf('day').toDate() } });
            if (resp && resp.message) {
                this.loading = false;
                if (resp.error) return emitToastr('error', resp.message);
                this.objects = resp.message;
            }

            this.totalKm = 0;
            this.totalPayment = 0;

            this.objects.forEach(o => {
                this.totalKm += o.totalKm;
                this.totalPayment += o.totalPayment;
            })

            this.totalPayment.toLocaleString()

            this.showSummary = true;
            this.loading = false;
        },
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.tab-card {
    background-color: #EAEAEA;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.vehicle-div {
    word-break: break-word;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.pictures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image-thumb {
    max-height: 160px;
    max-width: 80vw;
    border-radius: 4px;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  