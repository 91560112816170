<template>
    <div class="menu-page">
        <h1>Perfil</h1>

        <div>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="openNew">Novo perfil</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :headers="headers" :items="profiles" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="profile = item; dialogProfileView = true" v-bind="attrs" v-on="on"> mdi-eye-outline</v-icon>
                                    </template>
                                    <span>Ver</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="profile = item; dialogProfile = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="profile = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogProfile" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ profile._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogProfile = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="profile.name" label="Nome do perfil" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Área
                                            </th>
                                            <th class="text-left">
                                                Pesquisar
                                            </th>
                                            <th class="text-left">
                                                Alterar
                                            </th>
                                            <th class="text-left">
                                                Incluir
                                            </th>
                                            <th class="text-left">
                                                Excluir
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in areas" :key="item.id">
                                            <td>{{ item.name }}</td>
                                            <td><v-checkbox v-model="profile[item.id].read" label="Pesquisar" color="primary" hide-details></v-checkbox></td>
                                            <td><v-checkbox v-model="profile[item.id].update" label="Alterar" color="primary" hide-details></v-checkbox></td>
                                            <td><v-checkbox v-model="profile[item.id].create" label="Incluir" color="primary" hide-details></v-checkbox></td>
                                            <td><v-checkbox v-model="profile[item.id].delete" label="Excluir" color="primary" hide-details></v-checkbox></td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateProfile" :loading="buttonLoading">Salvar perfil</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>



        <v-dialog v-model="dialogProfileView" max-width="1000"> 
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>

                    <v-col cols="12" sm="6">
                        <span class="text-h4">{{ profile.name }}</span>
                    </v-col>

                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Área
                                        </th>
                                        <th class="text-left">
                                            Pesquisar
                                        </th>
                                        <th class="text-left">
                                            Alterar
                                        </th>
                                        <th class="text-left">
                                            Incluir
                                        </th>
                                        <th class="text-left">
                                            Excluir
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in areas" :key="item.id">
                                        <td>{{ item.name }}</td>
                                        <td><v-checkbox disabled v-model="profile[item.id].read" label="Pesquisar" color="primary" hide-details></v-checkbox></td>
                                        <td><v-checkbox disabled v-model="profile[item.id].update" label="Alterar" color="primary" hide-details></v-checkbox></td>
                                        <td><v-checkbox disabled v-model="profile[item.id].create" label="Incluir" color="primary" hide-details></v-checkbox></td>
                                        <td><v-checkbox disabled v-model="profile[item.id].delete" label="Excluir" color="primary" hide-details></v-checkbox></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar perfil
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o perfil <b>{{ profile.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; profile.filed = true; createOrUpdateProfile()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "PerfilView",

    data() {
        return {
            profiles: [],
            dialogProfile: false,
            dialogProfileView: false,
            dialog: false,
            search: '',
            areas: [
                { id: 'location', name: 'Localidade' },
                { id: 'section', name: 'Seção' },
                { id: 'fuel', name: 'Combustível' },
                { id: 'vehicle', name: 'Veículo' },
                { id: 'daily_record', name: 'Registro Diário' },
                { id: 'store_renovation', name: 'Reforma de Lojas' },
                { id: 'audit', name: 'Auditoria' },
                { id: 'report_rd', name: 'Relatório de RD' },
                { id: 'report_individual_audit', name: 'Relatório Auditoria Individual' },
                { id: 'report_audit', name: 'Relatório Auditoria' },
                { id: 'report_visits', name: 'Relatório Visitas de Auditoria' },
                { id: 'report_renovations', name: 'Relatório de Reformas' },
                { id: 'checklists', name: 'Checklist de Auditoria' },
            ],
            profile: {
                name: '',
                location: {},
                section: {},
                fuel: {},
                vehicle: {},
                daily_record: {},
                store_renovation: {},
                audit: {},
                reports: {}
            },
            headers: [
                { text: "Nome do perfil", value: "name" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getProfiles();
            if (resp && resp.message) {
                if (resp.error) return emitToastr('error', resp.message);
                this.profiles = resp.message;
            }
            this.loading = false;
        },

        async createOrUpdateProfile() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateProfile(this.profile);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogProfile = false;
        },

        openNew() {
            this.profile = {
                name: '',
                location: {},
                section: {},
                fuel: {},
                vehicle: {},
                daily_record: {},
                store_renovation: {},
                audit: {},
                report_rd: {},
                report_individual_audit: {},
                report_audit: {},
                report_visits: {},
                report_renovations: {},
                checklists: {},
            };
            this.dialogProfile = true;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  